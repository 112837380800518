import { styled } from "@creditas/stylitas";

import { Container as BaseContainer } from "@creditas/layout";

export const Container = styled(BaseContainer)<{ progressBarText: string }>`
  display: flex;
  padding: 40px;

  & > div {
    min-height: 430px;
  }

  svg.big-icon {
    opacity: 0.35;
    font-size: 250px;
  }

  h3 {
    margin-bottom: 20px;
  }

  ul {
    margin: 30px auto;

    li {
      margin-bottom: 5px;
      line-height: 2;
      list-style: none;
      div {
        margin-right: 10px;
      }
      p {
        display: inline-block;
      }
    }
  }

  div.progress-bar-wrapper > div:before {
    content: '${({ progressBarText }) => progressBarText}'
  }

  button {
    margin-top: 20px;
  }

  @media (max-width: 650px) {
    button {
      width: 100%;
    }
    button + button {
      margin: 20px 0;
    }
  }

  @media (max-width: 1080px) {
    > div:first-of-type {
      display: none;
    }
  }
`;
