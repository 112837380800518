import { useState, useMemo, useEffect } from "react";

import { InputSelect, InputSelectProps } from "@creditas/form-elements";
import { InputSelectOption } from "@creditas/form-elements/dist/InputSelectOption";

import { useCustomProps } from "../contexts/CustomPropsContext";
import { useGetCompaniesSettings } from "../services/bff/queries/useGetCompaniesSettings";
import { UserType } from "../interfaces/userType";

export function useSelectGroupId() {
  const { companySettingsId, userType } = useCustomProps();
  const [groupId, setGroupId] = useState(companySettingsId?.toString());
  const [getCompanySettings, { data }] = useGetCompaniesSettings();

  const isMasterUser = userType === UserType.MASTER;

  const companyGroupOptions = useMemo<InputSelectOption[]>(() => {
    if (!data) return [];
    return data.companySettings.map(({ id, groupName }) => ({
      text: groupName,
      value: id.toString()
    }));
  }, [data]);

  useEffect(() => {
    if (isMasterUser) getCompanySettings();
  }, []);

  const inputSelectProps: InputSelectProps = {
    value: groupId,
    size: "comfortable",
    options: companyGroupOptions,
    onChange: event => setGroupId(event.target.value)
  };

  return {
    groupId,
    setGroupId,
    isMasterUser,
    InputSelect,
    inputSelectProps
  };
}
