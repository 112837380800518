import { AnalyticsEvent, emitEvent } from "../../helpers/analytics";
import { Dispatch, SetStateAction, useState } from "react";
import { Step } from "./ImportHrEmployeeTypes";
import { useTranslation } from "react-i18next";

interface ImportHrEmployeeContainerHook {
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
  handleBackButton: (
    step: Step,
    setStep: Dispatch<SetStateAction<Step>>,
    event: AnalyticsEvent
  ) => void;
}

export const useImportHrEmployeeContainer = (): ImportHrEmployeeContainerHook => {
  const { t } = useTranslation();

  const [step, setStep] = useState<Step>({
    current: 1,
    uploadFileAsJson: null,
    uploadKey: null,
    uploadResponse: null,
    uploadId: null,
    uploadPreview: null,
    uploadSystemValues: null,
    matchColumnResponse: null,
    feedbackResponse: null,
    columnMap: null,
    additionalMaps: null,
    indexColumnsMap: null,
    systemValuesCellMappingNeeded: null,
    companySettingsIdSelectedByMaster: undefined,
    salaryType: undefined
  });

  const handleBackButton = (
    step: Step,
    setStep: Dispatch<SetStateAction<Step>>,
    event?: AnalyticsEvent
  ) => {
    if (event) emitEvent(event);
    const stepNumber = step.current;

    switch (stepNumber) {
      case 1:
      case 2:
        // eslint-disable-next-line no-alert
        if (confirm(t("hr.importEmployee.step2.confirmAlert"))) {
          setStep({
            ...step,
            uploadKey: null,
            current: step.current - 1
          });
        }
        break;
      case 3:
        // eslint-disable-next-line no-alert
        if (confirm(t("hr.importEmployee.step3.confirmAlert"))) {
          setStep({
            ...step,
            current: step.current - 1
          });
        }
        break;
      case 4:
        setStep({ ...step, current: step.current - 1 });
        break;
      case 5:
      default:
        setStep({ ...step });
    }
  };

  return {
    step,
    setStep,
    handleBackButton
  };
};
