import { styled, css, utilities } from "@creditas/stylitas";
import { Button } from "@creditas/button";

const styledPrimaryButton = ({ theme }) => css`
  background-color: #49e295;
  color: #000000;

  :hover {
    background-color: #49e295;
    box-shadow: 0px 16px 12px -8px rgb(0 0 0 / 16%),
      0px 16px 8px -12px rgb(0 0 0 / 12%);
    transition: box-shadow 0.3s ease-in-out;
  }

  :disabled {
    background-color: ${utilities.palette.color(theme, "neutral03")};
    box-shadow: none;
  }
`;

export const StyledPrimaryButton = styled(Button)`
  ${styledPrimaryButton}
`;
