import { styled } from "@creditas/stylitas";
import { Container as BaseContainer } from "@creditas/layout";
import { getColor } from "../../helpers/designSystem";

const Container = styled(BaseContainer)`
  margin: 20px auto;
  header {
    display: flex;
    margin: 20px auto;
    justify-content: space-between;

    :first-of-type {
      gap: 20px;
      flex-direction: column;
    }
  }
  a {
    text-decoration: none;
  }
  h5.page-title {
    color: ${getColor("neutral07")};
  }
  div.upload-count {
    display: flex;
    align-items: center;
    & > p {
      color: ${getColor("neutral05")};
      font-weight: bold;
      margin-right: 10px;
    }
  }
  div.spinner {
    margin-top: 60px;
  }
  div.pagination-table {
    border-top: 1px solid ${getColor("neutral03")};
    select {
      padding-left: 10px;
    }
    /* TODO: Add pageSize option to API to show this div*/
    & > div:last-child {
      display: none;
    }
  }
`;

export default Container;
