import { scrollBar } from "./scrollBar";
import { styled, ifProp, css } from "@creditas-ui/react";

interface Props {
  maxHeight?: string;
  height?: string;
  stickyHeader?: boolean;
}

export const ScrollArea = styled.div<Props>`
  ${({ theme }) => scrollBar(theme)}

  ${({ height }) =>
    ifProp(
      "height",
      css`
        height: ${height};
      `
    )}

  ${({ maxHeight }) =>
    ifProp(
      "maxHeight",
      css`
        max-height: ${maxHeight};
      `
    )}

  th {
    top: 0;
    position: ${ifProp("stickyHeader", "sticky")};
    z-index: 1;
    background: ${({ theme }) => theme.colors.neutral[0]};
  }

  td {
    vertical-align: middle;
  }
`;
