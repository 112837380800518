import BInfoPositive from "@creditas/brand-icons/dist/cS/BInfoPositive";
import { Button } from "@creditas/button";
import { Alert } from "@creditas/icons";
import { ProgressBar } from "@creditas/progress-bar";
import { useToaster } from "@creditas/toaster";
import { Typography } from "@creditas/typography";
import ConfirmEmployeeImportButton from "../../../components/ConfirmEmployeeImportButton/ConfirmEmployeeImportButton";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Container } from "../../../components/ImportingFeedbackDetail/ImportingFeedbackDetail.style";
import ErrorsList from "../../../components/WithoutSideEffect/ErrorsList/ErrorsList";
import { useCustomProps } from "../../../contexts/CustomPropsContext";
import { envVars } from "../../../envVars";
import { Sentry } from "../../../helpers";
import { emitEvent } from "../../../helpers/analytics";
import {
  EMPLOYEE_CONFIRM_IMPORT_ACTION,
  useEmployeeWithContracts
} from "../../../services/bff/mutations";
import {
  ImportResultType,
  useGetEmployeeDiffFromLastImport
} from "../../../services/bff/queries";
import { useGetFileLineErrors } from "../../../services/bff/queries/useGetFileLineErrors";
import { Step } from "../ImportHrEmployeeTypes";
import { FeedbackActionComponent } from "./components/FeedbackActionComponent/FeedbackActionComponent";
import { FeedbackErrorComponent } from "./components/FeedbackErrorComponent/FeedbackErrorComponent";
import { FeedbackSuccessComponent } from "./components/FeedbackSuccessComponent/FeedbackSuccessComponent";
import { FeedbackSummaryComponent } from "./components/FeedbackSummaryComponent/FeedbackSummaryComponent";
import { useStep4Container } from "./Step4ContainerHook";
import {
  CompanyDocumentCodeBulletPointList,
  Paragraph,
  ParagraphWithAlertContainer
} from "./Step4ContainerStyles";
import { StyledPrimaryButton } from "../../../shared/styles/primary-button.styles";
import { Product } from "../../../helpers/product";

interface Props {
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
}

export const Step4Container: React.FC<Props> = ({ step, setStep }: Props) => {
  const history = useHistory();
  const { companySettingsId, productType, onExit } = useCustomProps();
  const { t } = useTranslation();

  const {
    hookStateFileImportSummary,
    hookStateFileResult,
    getFileImportSummary,
    getFileResult,
    productBasePath,
    shouldCreateBenefitsCard,
    unregisteredCompanyDocumentCodeList,
    showCompanyDocumentCodeWarning
  } = useStep4Container({ step });

  const response = hookStateFileImportSummary.data?.getFileImportSummary;
  const sourceId = response?.fileId;

  const [percentage, setPercentage] = useState(0);
  const [progressBarText, setProgressBarText] = useState("");
  const [createBenefitsCard, setCreateBenefitsCard] = useState(false);

  const [getFileLineErrors, hookStateFileLineErrors] = useGetFileLineErrors();
  const { push } = useToaster();

  if (
    response?.importStatus === "ERROR" ||
    response?.importStatus === "SUCCESS"
  ) {
    if (hookStateFileImportSummary.stopPolling) {
      hookStateFileImportSummary.stopPolling();
      !hookStateFileLineErrors.called &&
        getFileLineErrors({
          variables: {
            id: step.uploadKey!,
            columnType: "CPF"
          }
        });
    }
  }

  if (hookStateFileImportSummary.error?.message) {
    const exception = new Error(hookStateFileImportSummary.error.message);
    const extra = {
      flow: "benefits",
      request: "getFileImportSummary",
      groupId: companySettingsId,
      correlationId: step.uploadKey
    };
    Sentry.captureExceptionHint(exception, extra);
  }

  if (hookStateFileLineErrors.error?.message) {
    const exception = new Error(hookStateFileLineErrors.error.message);
    const extra = {
      flow: "benefits",
      request: "getFileLineErrors",
      correlationId: step.uploadKey
    };
    Sentry.captureExceptionHint(exception, extra);
  }

  if (hookStateFileResult.error?.message) {
    const exception = new Error(hookStateFileResult.error.message);
    const extra = {
      flow: "benefits",
      request: "getFileResult",
      correlationId: step.uploadKey
    };
    Sentry.captureExceptionHint(exception, extra);
  }

  useEffect(() => {
    const success = response?.summary.SUCCESS ?? 0;
    const error = response?.summary.ERROR ?? 0;
    const scheduled = response?.summary.SCHEDULED ?? 0;
    const progress = success + error;
    const total = progress + scheduled;
    setPercentage((progress / total) * 100);
    setProgressBarText(`Processando ${progress} de ${total}`);
    if (
      (hookStateFileLineErrors.data && response?.importStatus === "ERROR") ||
      (hookStateFileLineErrors.data && response?.importStatus === "SUCCESS")
    ) {
      emitEvent({
        event: "gaCreditasEvent",
        category: "EmployeeImport",
        action: "FileImportProcessing",
        label: total,
        value: total,
        importedEmployeeFile: {
          processingRegisters: scheduled,
          successRegisters: success,
          errorRegisters: error
        }
      });
    }
    if (error > 0 && hookStateFileLineErrors.data) {
      const errorList = getFileLineErrorsDataToArrayOfStrings();
      errorList.forEach(error => {
        emitEvent({
          event: "gaCreditasEvent",
          category: `${productType} | importErrors`,
          action: "default",
          label: error
        });
      });
    }
  }, [response, hookStateFileLineErrors.data, productType]);

  useEffect(() => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "LoadImportSummaryModal",
      label: window.location.href
    });
  }, []);

  useEffect(() => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "LoadEmployeeImportStep4"
    });
  }, []);

  useEffect(() => {
    getFileImportSummary({
      variables: {
        id: step.uploadKey!,
        groupId:
          step.companySettingsIdSelectedByMaster ||
          companySettingsId?.toString() ||
          ""
      }
    });
  }, [
    step.uploadKey,
    step.companySettingsIdSelectedByMaster,
    companySettingsId,
    getFileImportSummary
  ]);

  useEffect(() => {
    setCreateBenefitsCard(shouldCreateBenefitsCard(step));
  }, [step, shouldCreateBenefitsCard]);

  const getFileLineErrorsDataToArrayOfStrings = () =>
    Object.keys(
      hookStateFileLineErrors.data!.employeeImportGetFileLineErrors.errors
    ).map(
      key =>
        `Na linha do CPF: "${key}" - Encontre a coluna ${hookStateFileLineErrors.data?.employeeImportGetFileLineErrors.errors[
          key
        ].join(" |  Encontre a coluna ")}`
    );

  const downloadErrors = (id: string, type: ImportResultType) => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "ImportDownloadErrors"
    });
    getFileResult({
      variables: {
        input: {
          id,
          type
        }
      }
    });
  };

  const handleCancel = () => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "ImportCancel"
    });
    const isCancelled = confirm(
      t("hr.importEmployee.step4.feedbackDetails.processingError.cancelAlert")
    );
    if (isCancelled) {
      history.push(`${productBasePath}`);
    }
  };

  const [action, setAction] = useState<EMPLOYEE_CONFIRM_IMPORT_ACTION>();
  const [showSelectAction, setShowSelectAction] = useState(false);

  const [
    getEmployeeDiffFromLastImport,
    { data: employeeDiffFromLastImport }
  ] = useGetEmployeeDiffFromLastImport(
    sourceId,
    step.companySettingsIdSelectedByMaster ||
      companySettingsId?.toString() ||
      ""
  );

  useEffect(() => {
    if (
      (hookStateFileLineErrors.data && response?.importStatus === "ERROR") ||
      (hookStateFileLineErrors.data && response?.importStatus === "SUCCESS")
    ) {
      getEmployeeDiffFromLastImport();
    }
  }, [
    hookStateFileLineErrors.data,
    response?.importStatus,
    getEmployeeDiffFromLastImport
  ]);

  const [employeeWithContracts] = useEmployeeWithContracts();
  const handleChangeAction = selectedAction => {
    setAction(selectedAction as EMPLOYEE_CONFIRM_IMPORT_ACTION);
  };
  const [employeesWithActiveLoan, setEmployeesWithActiveLoan] = useState<
    string[]
  >([]);

  const navigateToList = () => {
    if (productType !== Product.BENEFITS) {
      setStep(previousState => ({
        ...previousState,
        current: previousState.current + 1
      }));
    } else {
      onExit ? onExit() : history.push(`${productBasePath}`);
    }
  };

  const handleConfirmSuccess = () => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "ImportSuccess"
    });

    !!employeeDiffFromLastImport?.employeeDiffFromLastImport.fired.length ||
    !!employeeDiffFromLastImport?.employeeDiffFromLastImport.absent.length
      ? handleDeleteEmployees()
      : navigateToList();
  };

  const handleDeleteEmployees = () => {
    const employeesWithTypename = [
      ...(employeeDiffFromLastImport?.employeeDiffFromLastImport.fired || []),
      ...(action === "REMOVE"
        ? employeeDiffFromLastImport?.employeeDiffFromLastImport.absent || []
        : [])
    ];

    const allEmployees = employeesWithTypename.map(employee => ({
      id: employee.id,
      name: employee.name,
      mainDocument: {
        code: employee.mainDocument.code,
        type: employee.mainDocument.type
      }
    }));

    const promises: Array<Promise<any>> = [];
    for (let i = 0; i < allEmployees.length; i += 500) {
      const employees = allEmployees.slice(i, i + 500);
      promises.push(
        employeeWithContracts({
          variables: {
            payload: {
              employees
            }
          }
        })
      );
    }

    Promise.all(promises)
      .then(responses => {
        const employeesWithActiveLoans = responses
          .map(function(element) {
            return element.data.employeeWithContracts.employees;
          })
          .reduce(function(a, b) {
            return a.concat(b);
          }, [])
          .map(
            employee =>
              `Colaborador com contrato ativo - ${employee.mainDocument.type}: ${employee.mainDocument.code}`
          );

        !!employeesWithActiveLoans.length
          ? setEmployeesWithActiveLoan(employeesWithActiveLoans || [])
          : navigateToList();
      })
      .catch(error => {
        push(`Erro ao delete colaboradores`, {
          level: "danger"
        });
        const extra = {
          flow: "benefits",
          request: "employeesDelete",
          correlationId: sourceId
        };
        Sentry.captureExceptionHint(error as Error, extra);
      });
  };

  const confirmActionOptions: any = [
    {
      value: "REMOVE",
      text: "Remover esses colaboradores da base"
    },
    {
      value: "KEEP",
      text: "Manter esses colaboradores na base"
    }
  ];

  useEffect(() => {
    if (!employeeDiffFromLastImport?.employeeDiffFromLastImport.diff) {
      setShowSelectAction(true);
      setAction("KEEP");
    } else if (employeeDiffFromLastImport) {
      setAction("KEEP");
      setShowSelectAction(true);
    }
  }, [employeeDiffFromLastImport]);

  const renderCompanyCodeList = [
    ...Array.from(new Set(unregisteredCompanyDocumentCodeList)).map(
      (item, index) => {
        return <li key={index}>{item}</li>;
      }
    )
  ];

  const handleRetry = () => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "ImportRetry",
      label: window.location.href
    });
    history.push(`${productBasePath}`);
  };

  return (
    <Container maxWidth="lg" progressBarText={progressBarText}>
      <div style={{ flexBasis: "25%", margin: 0 }}>
        <BInfoPositive className="big-icon" />
      </div>
      <div style={{ flex: 1 }}>
        {hookStateFileImportSummary.data !== undefined && (
          <FeedbackSummaryComponent
            scheduled={response?.summary.SCHEDULED ?? 0}
            success={response?.summary.SUCCESS ?? 0}
            error={response?.summary.ERROR ?? 0}
          />
        )}

        {["IN_PROGRESS", "WAITING"].includes(response?.importStatus ?? "") &&
          hookStateFileImportSummary.data !== undefined && (
            <div className="progress-bar-wrapper">
              <ProgressBar percentage={percentage} />
            </div>
          )}
        <>
          {response?.importStatus === "SUCCESS" && (
            <>
              {showSelectAction && employeesWithActiveLoan.length <= 0 && (
                <>
                  <FeedbackActionComponent
                    action={action}
                    data={employeeDiffFromLastImport}
                    confirmActionOptions={confirmActionOptions}
                    handleChangeAction={handleChangeAction}
                    loading={employeeDiffFromLastImport ? false : true}
                  />
                  {showCompanyDocumentCodeWarning && (
                    <>
                      <ParagraphWithAlertContainer>
                        <Alert.AlertTriangle color="warning" />
                        <Typography
                          variant="textParagraph"
                          style={{ fontSize: 20, lineHeight: 1.5 }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {t(
                              "hr.importEmployee.step4.feedbackDetails.processingSuccess.unregisteredCompanyDocumentCodeWarning.text_1"
                            )}
                          </span>{" "}
                          {t(
                            "hr.importEmployee.step4.feedbackDetails.processingSuccess.unregisteredCompanyDocumentCodeWarning.text_2"
                          )}
                        </Typography>
                      </ParagraphWithAlertContainer>
                      <CompanyDocumentCodeBulletPointList>
                        {renderCompanyCodeList}
                      </CompanyDocumentCodeBulletPointList>
                      <Paragraph>
                        {t(
                          "hr.importEmployee.step4.feedbackDetails.processingSuccess.unregisteredCompanyDocumentCodeWarning.text_3"
                        )}
                      </Paragraph>
                    </>
                  )}
                  <FeedbackSuccessComponent
                    isButtonDisabled={
                      response?.summary.SUCCESS == 0 &&
                      response?.summary.ERROR == 0
                    }
                  />
                </>
              )}
            </>
          )}
          {response?.importStatus === "ERROR" &&
            hookStateFileLineErrors.data &&
            step.uploadKey !== null && (
              <>
                {showSelectAction && employeesWithActiveLoan.length <= 0 && (
                  <>
                    {response?.summary.SUCCESS &&
                      response?.summary.SUCCESS >= 1 && (
                        <>
                          <FeedbackActionComponent
                            action={action}
                            data={employeeDiffFromLastImport}
                            confirmActionOptions={confirmActionOptions}
                            handleChangeAction={handleChangeAction}
                            loading={employeeDiffFromLastImport ? false : true}
                            employeesWithSuccess={response?.summary.SUCCESS}
                          />
                        </>
                      )}
                    <FeedbackErrorComponent
                      onDownloadErrors={downloadErrors}
                      fileLineErrorsData={getFileLineErrorsDataToArrayOfStrings()}
                      step={step}
                      isButtonDisabled={
                        response?.summary.SUCCESS == 0 &&
                        response?.summary.ERROR == 0
                      }
                    />
                  </>
                )}
              </>
            )}

          {response?.importStatus === "SUCCESS" ||
          (response?.importStatus === "ERROR" &&
            response?.summary.SUCCESS &&
            hookStateFileLineErrors.data &&
            step.uploadKey !== null) ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "baseline"
              }}
            >
              <Button
                variant="tertiary"
                style={{
                  color: "#1F2D27",
                  display: "inline",
                  cursor: "pointer",
                  paddingRight: 80
                }}
                onClick={handleCancel}
              >
                {t(
                  "hr.importEmployee.step4.feedbackDetails.processingError.cancel"
                )}
              </Button>
              <ConfirmEmployeeImportButton
                id={sourceId!}
                createBenefitsCard={createBenefitsCard}
                action={action}
                isDisabled={
                  response?.summary.SUCCESS == 0 && response?.summary.ERROR == 0
                }
                groupId={step.companySettingsIdSelectedByMaster}
                onSuccess={handleConfirmSuccess}
              />
            </div>
          ) : (
            <></>
          )}

          {response?.summary.ERROR &&
            response?.summary.SUCCESS == null &&
            hookStateFileLineErrors.data &&
            step.uploadKey !== null && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "baseline"
                }}
              >
                <StyledPrimaryButton variant="primary" onClick={handleRetry}>
                  {t(
                    "hr.importEmployee.step4.feedbackDetails.processingError.tryAgain"
                  )}
                </StyledPrimaryButton>
              </div>
            )}
        </>

        {!!employeesWithActiveLoan.length && (
          <div style={{ paddingTop: 30 }}>
            <Typography
              variant={"textParagraph"}
              style={{ fontSize: 20, lineHeight: 1.5, marginBottom: 10 }}
            >
              A sua importação foi feita com sucesso, e os colaboradores
              removidos já não podem ter acesso a nenhum produto Creditas, mas
              alguns deles tinham contratos ativos e você precisa lançar a
              rescisões deles:
            </Typography>
            <ErrorsList errors={employeesWithActiveLoan} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "baseline"
              }}
            >
              <Button
                variant="tertiary"
                style={{
                  color: "#1F2D27",
                  display: "inline",
                  cursor: "pointer",
                  paddingRight: 80
                }}
                onClick={navigateToList}
              >
                {t("Fazer depois")}
              </Button>

              <Button
                variant="primary"
                onClick={() =>
                  window.location.assign(`${envVars.ADMIN_URL}/emprestimos`)
                }
              >
                {t("Lançar as rescisões desses colaboradores")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};
