// @ts-ignore
import Worker from "./xlsx.worker.js";

export default class XlsxWorker {
  private worker: Worker;

  constructor() {
    this.worker = new Worker();
  }

  xlsxToArray(xlsx: File): Promise<{ showWarning: boolean; preview: any[][] }> {
    return new Promise((resolve, reject) => {
      this.worker.onerror = reject;
      this.worker.onmessage = ({ data }) => resolve(data);
      this.worker.postMessage({
        action: "xlsxToArrayOfArrays",
        payload: xlsx
      });
    });
  }
}
