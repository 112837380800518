import { Dispatch, SetStateAction, useState } from "react";
import { emitEvent, Sentry } from "../../../helpers";
import { useSetFileConfiguration } from "../../../services/bff/mutations";
import { Step } from "../ImportHrEmployeeTypes";
import { useToaster } from "@creditas/toaster";
import { useCustomProps } from "../../../contexts/CustomPropsContext";
import { getImportTypeByProduct } from "../../../helpers/getImportTypeByProduct";

interface Step3ContainerHook {
  submitValuesMapping: ({ status: any, employeeType: string }) => void;
  submitting: boolean;
}

export const useStep3Container = (
  step: Step,
  setStep: Dispatch<SetStateAction<Step>>
): Step3ContainerHook => {
  const [setFileConfiguration] = useSetFileConfiguration();
  const [submitting, setSubmitting] = useState(false);
  const { push } = useToaster();
  const { productType } = useCustomProps();

  const submitValuesMapping = additionalMaps => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    setStep(previousState => ({
      ...previousState,
      additionalMaps: additionalMaps
    }));
    setFileConfiguration({
      variables: {
        key: step.uploadKey!,
        columnsMap: step.columnMap!,
        additionalMaps: additionalMaps,
        importType: getImportTypeByProduct(productType)
      }
    })
      .then(() => {
        setSubmitting(false);
        emitEvent({
          event: "gaCreditasEvent",
          category: "EmployeeImport",
          action: "ConfirmStatusMapped"
        });

        setStep(previousState => ({
          ...previousState,
          current: previousState.current + 1
        }));
      })
      .catch(error => {
        const exception = new Error(error);
        const extra = {
          flow: "benefits",
          request: "setFileConfiguration",
          data: "columnsMap, additionalMaps",
          correlationId: step.uploadKey
        };
        Sentry.captureExceptionHint(exception, extra);
        setSubmitting(false);
        emitEvent({
          event: "gaCreditasEvent",
          category: "EmployeeImport",
          action: "ConfirmStatusMapped",
          label: { details: error }
        });
        push(
          "Ocorreu um erro, por favor, recomece o processo importando a planilha novamente.",
          { level: "danger", delay: -1 }
        );
      });
  };
  return { submitValuesMapping, submitting };
};
