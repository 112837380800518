import { gql, useQuery } from "@creditas/apollo";
import { Paginated } from "../../../interfaces";

export const DEXTER_GET_EMPLOYEE_IMPORTS = gql`
  query getEmployeeImports($offset: Int) {
    employeeImports(offset: $offset) {
      total
      limit
      offset
      data {
        id
        companyName
        importerName
        createdAt
        step
        status
        filename
        canDownloadError
      }
    }
  }
`;

export const useDexterGetEmployeeImports = (page = 1) => {
  const queryResult = useQuery<Response>(DEXTER_GET_EMPLOYEE_IMPORTS, {
    variables: {
      offset: page
    }
  });
  if (queryResult.data) {
    const employeeImports = queryResult.data.employeeImports.data.map(item => {
      const dateRegExp = /(\d{4})-(\d{2})-(\d{2})\s(\d{1,2}):(\d{2}).*/;
      const formatedDate =
        item.createdAt && item.createdAt.replace(dateRegExp, "$3/$2/$1 $4:$5");
      return { ...item, createdAt: formatedDate };
    });
    return {
      ...queryResult,
      data: {
        employeeImports: {
          ...queryResult.data.employeeImports,
          data: employeeImports
        }
      }
    };
  }
  return queryResult;
};

interface Response {
  employeeImports: Paginated<EmployeeImmport>;
}

export interface EmployeeImmport {
  id: number;
  companyName: string;
  importerName: string;
  createdAt: string;
  step: string;
  status: string;
  filename: string;
  canDownloadError: boolean;
  __typename: string;
}
