import { SystemValue } from "components/MatchColumn/interfaces";
import { DexterUploadSuccess, Preview } from "interfaces";
import { AdditionalMaps, ColumnMap, MapResponse } from "services/bff/mutations";

export interface Step {
  current: number;
  uploadFileAsJson: string[][] | null;
  uploadKey: string | null;
  uploadResponse: DexterUploadSuccess | null;
  uploadId: number | null;
  uploadPreview: Preview | null;
  uploadSystemValues: SystemValue[] | null;
  matchColumnResponse: MapResponse | null;
  systemValuesCellMappingNeeded: string[] | null;
  feedbackResponse: MapResponse | null;
  columnMap: ColumnMap | null;
  indexColumnsMap: any;
  additionalMaps: AdditionalMaps | null;
  companySettingsIdSelectedByMaster: string | undefined;
  salaryType: SalaryType | undefined;
}

export const routeStepParams = {
  1: "upload",
  2: "column",
  3: "status",
  4: "confirm"
};

export enum SalaryType {
  NET_SALARY = "NET_SALARY",
  BASE_SALARY = "BASE_SALARY",
  PRE_ESTABLISHED_MARGIN = "PRE_ESTABLISHED_MARGIN"
}
