import { styled, css, utilities } from "@creditas/stylitas";

const background = ({ theme }) => css`
  background: ${utilities.palette.color(theme, "neutral02")};
`;

const borderRadius = ({ theme }) => css`
  ${utilities.radius.medium({ theme })};
`;

const dashedBorder = ({ theme }) => css`
  border: 1px dashed ${utilities.palette.color(theme, "neutral09")};
`;

const dragActiveStyle = ({ theme }) => css`
  ${utilities.surface.focus({ theme })}

  svg {
    fill: ${utilities.palette.color(theme, "secondary03")};
  }
`;

interface Props {
  dragActive?: boolean;
  hasFocus?: boolean;
}

const Container = styled.label<Props>`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${background}
  ${borderRadius}
  ${dashedBorder}

  svg {
    flex: 1;
    height: 2em;
    fill: ${({ theme }) => utilities.palette.color(theme, "primary01")};
  }

  ${({ dragActive, hasFocus }) => (dragActive || hasFocus) && dragActiveStyle}
`;

export default Container;
