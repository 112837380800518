export const ONLY_PAYROLL_SYSTEM_VALUES_BASE_SALARY = [
  {
    key: "NAME",
    label: "Nome completo",
    required: false
  },
  {
    key: "CPF",
    label: "CPF",
    required: true
  },
  {
    key: "CNPJ",
    label: "CNPJ",
    required: true
  },
  {
    key: "SALARY",
    label: "Salário base",
    required: false
  },
  {
    key: "STATUS",
    label: "Situação",
    required: false,
    valuesMap: {
      name: "statusMap",
      values: [
        {
          text: "Ativo",
          value: "ACTIVE"
        },
        {
          text: "Desligado",
          value: "FIRED"
        },
        {
          text: "Afastado",
          value: "ON_LEAVE"
        },
        {
          text: "Bloqueado",
          value: "BLOCKED"
        }
      ]
    }
  },
  {
    key: "ADMISSION_DATE",
    label: "Data de Admissão",
    required: true
  },
  {
    key: "EMPLOYEE_CODE",
    label: "Matricula",
    required: false
  },
  {
    key: "OTHER_PAYROLL_LOANS",
    label: "Possui outros consignados?",
    required: false
  },
  {
    key: "EMAIL",
    label: "Email pessoal",
    required: false
  },
  {
    key: "TELEPHONE",
    label: "Celular",
    required: false
  }
];
