import { css, styled } from "@creditas/stylitas";
import { Typography } from "@creditas/typography";

const paragraphWithAlertContainer = () => css`
  display: flex;
  margin-top: 40px;
`;

const companyDocumentCodeBulletPointList = () => css`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;

  li {
    margin-left: 20px;
    list-style: inherit;
  }
`;

const paragraph = () => css`
  font-size: 20px;
  line-height: 1.5;
  padding-bottom: 20px;
`;

export const ParagraphWithAlertContainer = styled.div`
  ${paragraphWithAlertContainer}
`;

export const CompanyDocumentCodeBulletPointList = styled.ul`
  && {
    ${companyDocumentCodeBulletPointList}
  }
`;

export const Paragraph = styled(Typography)`
  ${paragraph}
`;
