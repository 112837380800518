import { start, registerApplication } from "single-spa";
import "babel-polyfill";

import { CONFIGURATION } from "../cypress/support/configuration";
import { envVars } from "./envVars";
import { defaultCustomProps } from ".";

function getLifeCycles(path: string) {
  return () => window.System.import(path);
}

registerApplication({
  name: envVars.appName,
  app: getLifeCycles(`/employee-import/${envVars.appName}.js`),
  activeWhen: () => true,
  customProps: {
    ...defaultCustomProps,
    authToken: sessionStorage.authToken || CONFIGURATION.hr.authToken,
    authHrPortalToken:
      sessionStorage.authHrPortalToken || CONFIGURATION.hr.authHrPortalToken,
    companySettingsId:
      sessionStorage.companySettingsId || CONFIGURATION.hr.companySettingsId
  }
});

start();
