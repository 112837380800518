import {
  InMemoryCache,
  NormalizedCacheObject,
  ApolloClient as ApolloClient_
} from "@creditas/apollo";
import { createUploadLink } from "apollo-upload-client";

import { envVars } from "../../envVars";

export class ApolloClient {
  private static client: ApolloClient_<NormalizedCacheObject>;

  static getAuthorizedClient(authorization: string) {
    if (!this.client) {
      this.client = this.createClient(authorization);
    }
    return this.client;
  }

  private static createClient(authorization: string) {
    const uploadLink = createUploadLink({
      uri: envVars.BFF_URI,
      headers: { authorization }
    });
    return new ApolloClient_({
      link: uploadLink,
      cache: new InMemoryCache()
    });
  }
}
