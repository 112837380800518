import React, { Component } from "react";
import { ApolloProvider } from "@creditas/apollo";

import tokens from "@creditas/tokens";
import { Global } from "@creditas/global";
import { ThemeProvider as ThemeProviderLegacy } from "@creditas/stylitas";
import { ThemeProvider, standard } from "@creditas-ui/react";
import { ToasterProvider } from "@creditas/toaster";

import { AppCustomProps, defaultCustomProps } from ".";
import { CustomPropsProvider } from "./contexts/CustomPropsContext";

import { Sentry } from "./helpers";
import { ApolloClient } from "./services/bff/apollo-client";
import Router from "./pages/Router/Router";

export class App extends Component<AppCustomProps> {
  readonly state = { hasError: false, hasPendencies: false };

  componentDidCatch(error) {
    this.setState({ hasError: true });
    Sentry.captureException(error);
  }

  render() {
    const customProps = { ...defaultCustomProps, ...this.props };
    const apolloClient = ApolloClient.getAuthorizedClient(this.props.authToken);

    return this.state.hasError ? (
      <div>Something went wrong</div>
    ) : (
      <CustomPropsProvider value={customProps}>
        <ApolloProvider client={apolloClient}>
          <ThemeProviderLegacy theme={tokens}>
            <ThemeProvider theme={standard}>
              <ToasterProvider>
                <Global />
                <Router />
              </ToasterProvider>
            </ThemeProvider>
          </ThemeProviderLegacy>
        </ApolloProvider>
      </CustomPropsProvider>
    );
  }
}
