import { Typography } from "@creditas/typography";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isButtonDisabled: boolean;
}

export const FeedbackSuccessComponent: React.FC<Props> = ({
  isButtonDisabled
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {isButtonDisabled && (
        <Typography
          variant="textParagraph"
          style={{ display: "inline", fontSize: 20, lineHeight: 1.5 }}
        >
          {t(
            "hr.importEmployee.step4.feedbackDetails.processingError.uploadErrorDescription.text_5"
          )}
        </Typography>
      )}
    </div>
  );
};
