import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@creditas-ui/react";
import { Step } from "../ImportHrEmployeeTypes";
import { useCustomProps } from "../../../contexts/CustomPropsContext";
import { getImportProgressSummary } from "../../../api/hr-portal-bff/hr-portal-bff.api";
import { envVars } from "../../../envVars";
import { TAB_VALUE } from "../../../helpers/tab";
import { SummaryStatus } from "../../../helpers/summaryStatus";
import { emitEvent, Sentry } from "../../../helpers";
import { step5Analytics } from "../ImportHrEmployeeAnalyticsConstants";
import {
  ImportProgressSummary,
  ImportProgressSummaryComponent
} from "./components/ImportProgressSummaryComponent/ImportProgressSummaryComponent";
import { ImportProgressResultComponent } from "./components/ImportProgressResultComponent/ImportProgressResultComponent";
import { Product } from "../../../helpers/product";

interface Props {
  step: Step;
}

export const Step5Container: React.FC<Props> = ({ step }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { entryFlow, productBasePath, productType, onExit } = useCustomProps();
  const [progressBarText, setProgressBarText] = useState<string>(
    t("hr.importEmployee.step5.progressSummary.progressBarText", {
      processed: 0,
      total: 0
    })
  );
  const pollingInterval = 5000;
  const pollingError =
    "Received backend data equals the one from previous poll";

  const handleExit = () => {
    onExit ? onExit() : history.push(`${productBasePath}`);
  };

  const exitAndSendAnalyticsEvent = onExit => {
    onExit(false, summary.hasPendencies);
    emitEvent({
      event: step5Analytics.goToEmployeesWhileStillProcessingImport.event,
      category: step5Analytics.goToEmployeesWhileStillProcessingImport.category,
      action: step5Analytics.goToEmployeesWhileStillProcessingImport.action
    });
  };

  const handleExitWhileStillPollingData = () => {
    onExit
      ? exitAndSendAnalyticsEvent(onExit)
      : history.push(`${productBasePath}`);
  };

  const handleGoToPendenciesTab = () => {
    history.push(`${productBasePath}`, TAB_VALUE.PENDENCIES);
  };

  const summary = useMemo<ImportProgressSummary>((): ImportProgressSummary => {
    return {
      total: -1,
      processed: -1,
      waitingPreviousValue: -1,
      hasPendencies: false,
      status: SummaryStatus.INIT
    };
  }, []);

  let intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    emitEvent({
      event: step5Analytics.processingImport.event,
      category: step5Analytics.processingImport.category,
      action: step5Analytics.processingImport.action
    });
  }, []);

  useEffect(() => {
    const retrieveImportProgressSummary = async () => {
      const importProgressSummary = await getImportProgressSummary({
        url: envVars.HR_PORTAL_BFF_URL,
        sourceId: `FILEIMP-${step.uploadKey}`
      });

      if (importProgressSummary) {
        summary.total = importProgressSummary.total;
        summary.processed =
          importProgressSummary.total - importProgressSummary.waiting;
        summary.hasPendencies = importProgressSummary.withPendencies > 0;
        summary.status =
          importProgressSummary.waiting == 0
            ? SummaryStatus.PROCESSED
            : SummaryStatus.PROCESSING;

        if (summary.status === SummaryStatus.PROCESSED) {
          intervalRef.current && clearInterval(intervalRef.current);
        }
        if (importProgressSummary.waiting === summary.waitingPreviousValue) {
          intervalRef.current && clearInterval(intervalRef.current);
          summary.status = SummaryStatus.PROCESSED;
          summary.processed = 0;
          const exception = new Error(pollingError);
          const extra = {
            flow: entryFlow,
            product: productType,
            request: "getImportProgressSummary",
            uploadKey: step.uploadKey
          };
          Sentry.captureExceptionHint(exception, extra);
        } else {
          summary.waitingPreviousValue = importProgressSummary.waiting;
        }

        setProgressBarText(
          t("hr.importEmployee.step5.progressSummary.progressBarText", {
            processed: summary.processed,
            total: summary.total
          })
        );
      }
    };

    intervalRef.current = setInterval(() => {
      retrieveImportProgressSummary();
    }, pollingInterval);
  }, [step.uploadKey, summary, t]);

  return (
    <>
      {productType !== Product.BENEFITS && (
        <Grid gridTemplateColumns={"repeat(12, 1fr)"}>
          {(summary.status == SummaryStatus.INIT ||
            summary.status == SummaryStatus.PROCESSING) && (
            <ImportProgressSummaryComponent
              summary={summary}
              progressBarText={progressBarText}
              onClick={handleExitWhileStillPollingData}
            />
          )}
          {summary.status == SummaryStatus.PROCESSED && (
            <ImportProgressResultComponent
              hasPendencies={summary.hasPendencies}
              onClick={
                summary.hasPendencies ? handleGoToPendenciesTab : handleExit
              }
            />
          )}
        </Grid>
      )}
    </>
  );
};
