import { BrowserClient, EventHint, Hub } from "@sentry/browser";
import { envVars } from "../envVars";

export class Sentry {
  static hub: Hub;

  static init() {
    if (this.hub) return;
    const client = new BrowserClient({
      dsn: envVars.sentryDsn,
      release: envVars.release,
      environment: envVars.environment,
      debug: envVars.environment != "production"
    });
    this.hub = new Hub(client);
  }

  static captureException(exception: any): string {
    return this.hub.captureException(exception);
  }

  static captureExceptionHint(exception: Error, extra: any): string {
    const context = {
      extra: extra
    };
    const hint: EventHint = { captureContext: context };
    return this.hub.captureException(exception, hint);
  }
  static captureMessage(message: string): string {
    return this.hub.captureMessage(message);
  }
}
