import { styled, css, utilities } from "@creditas/stylitas";

import { Container as BaseContainer } from "@creditas/layout";
import { Checkbox } from "@creditas/checkbox";

const checkbox = () => css`
  textalign: "left";
  padding-top: 30px;
  padding-bottom: 20px;

  label {
    pointer-events: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const HrCheckbox = styled(Checkbox)`
  ${checkbox}
`;

const borderRadius = ({ theme }) => css`
  ${utilities.radius.small({ theme })};
`;

const borderAndBackground = ({ theme }) => css`
  background: ${utilities.palette.color(theme, "neutral01")};
  border: 1px solid ${utilities.palette.color(theme, "neutral03")};
`;

const Container = styled(BaseContainer)`
  padding: 40px;
  height: 700px;
  max-width: 480px;

  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  ${borderRadius}
  ${borderAndBackground}

  .w100p {
    width: 100%;
  }

  div {
    &:first-of-type label {
      margin: auto;
    }

    p + p,
    button + button {
      margin-top: 10px;
    }
  }

  select {
    width: 100%;
  }
`;

export default Container;
