import { Wizard } from "@creditas/wizard";
import { useCustomProps } from "../../contexts/CustomPropsContext";
import { Product } from "../../helpers/product";
import React from "react";
import withTracker from "../../withTracker";
import { useImportHrEmployeeContainer } from "./ImportHrEmployeeHook";
import Container from "./ImportHrEmployeeStyles";
import { Step1Container } from "./step1/Step1Container";
import { Step2Container } from "./step2/Step2Container";
import { Step3Container } from "./step3/Step3Container";
import { Step4Container } from "./step4/Step4Container";
import { Step5Container } from "./step5/Step5Container";

const ImportHrEmployeeContainer: React.FC = () => {
  const { step, setStep, handleBackButton } = useImportHrEmployeeContainer();
  const { productType } = useCustomProps();

  const calculateNumberOfSteps = () => {
    return productType !== Product.BENEFITS ? [1, 2, 3, 4, 5] : [1, 2, 3, 4];
  };

  const steps = {
    1: <Step1Container step={step} setStep={setStep} />,
    2: (
      <Step2Container
        step={step}
        setStep={setStep}
        handleBackButton={handleBackButton}
      />
    ),
    3: (
      <Step3Container
        step={step}
        setStep={setStep}
        handleBackButton={handleBackButton}
      />
    ),
    4: <Step4Container step={step} setStep={setStep} />,
    5: <Step5Container step={step} />
  };

  return (
    <Container>
      <div style={{ margin: "40px 0" }}>
        <Wizard
          steps={calculateNumberOfSteps()}
          currentStep={step.current}
          component="div"
          direction="fix-on-ds"
          className="fix-on-ds"
          data-testid="wizard"
        />
      </div>
      {steps[step.current]}
    </Container>
  );
};

export default withTracker(ImportHrEmployeeContainer);
