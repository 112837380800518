import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Spinner } from "@creditas/spinner";
import { Typography } from "@creditas/typography";
import { PaginationTable } from "@creditas/pagination";

import RoundedPlusIcon from "../../components/RoundedPlusIcon/RoundedPlusIcon";

import withTracker from "../../withTracker";
import { useSelectGroupId } from "../../hooks/useSelectGroupId";
import { emitEventByAttribute } from "../../helpers/analytics";
import { useCustomProps } from "../../contexts/CustomPropsContext";
import { usePaginationTranslation } from "../../hooks/usePaginationTranslation";
import EmployeeImportsTable from "../../components/EmployeeImportsTable/EmployeeImportsTable";
import { useGetEmployeeImports } from "../../services/bff/queries/useGetEmployeeImports";
import Container from "./EmployeeImportsList.style";

const EmployeeImportsList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { productBasePath } = useCustomProps();

  const {
    groupId,
    isMasterUser,
    InputSelect,
    inputSelectProps
  } = useSelectGroupId();

  const [page, setPage] = useState<number>(() => {
    const { searchParams } = new URL(window.location.href);
    const currentPage = Number(searchParams.get("page") || 1);
    return isNaN(currentPage) ? 1 : currentPage;
  });
  const paginationTranslation = usePaginationTranslation();

  const size = 10;
  const { data, loading, refetch } = useGetEmployeeImports(
    {
      page: 0,
      size
    },
    groupId
  );

  useEffect(() => {
    history && history.replace({ search: `page=${page}` });
    refetch({
      pagination: { page: page - 1, size },
      groupId
    });
  }, [groupId, page]);

  return (
    <Container maxWidth="xl">
      <header>
        <Typography variant="h4" className="page-title">
          {t("label.latestImports.employeeImport")}
        </Typography>
        {isMasterUser && (
          <InputSelect
            {...inputSelectProps}
            label={t("label.latestImports.listImportsFromGroup")}
          />
        )}
      </header>
      <header>
        <div className="upload-count">
          <Typography variant="support">
            {t("label.latestImports.uploads")}
          </Typography>
          {/* <CustomTag label={String(imports.total || 0)} /> */}
        </div>
        <Link
          role="button"
          onClick={emitEventByAttribute}
          data-event-action="newEmployeeImpot"
          to={`${productBasePath}/upload`}
          data-testid="new-employee-import"
          title={t("label.newEmployeeImport")}
        >
          <RoundedPlusIcon color="white" fontSize="large" />
        </Link>
      </header>
      {loading ? (
        <Spinner className="spinner" message={t("label.loading")} />
      ) : (
        <>
          <EmployeeImportsTable
            imports={data?.employeeImportGetImports.content || []}
          />
          <PaginationTable
            current={page}
            total={
              data
                ? Math.ceil(data.employeeImportGetImports.totalElements / size)
                : 1
            }
            onChangePage={setPage}
            className="pagination-table"
            translations={paginationTranslation}
          />
        </>
      )}
    </Container>
  );
};

export default withTracker(EmployeeImportsList);
