import { gql, useMutation } from "@creditas/apollo";
export interface ColumnMap {
  EMPLOYEE_CODE?: string;
  NAME?: string;
  MOTHER_NAME?: string;
  BIRTH_DATE?: string;
  NATIONALITY?: string;
  CPF?: string;
  CNPJ?: string;
  MARGIN?: string;
  SALARY?: string;
  ADMISSION_DATE?: string;
  OTHER_PAYROLL_LOANS?: string;
  STATUS?: string;
  EMPLOYEE_TYPE?: string;
  EMAIL?: string;
  TELEPHONE_TYPE?: string;
  COUNTRY_CODE?: string;
  AREA_CODE?: string;
  TELEPHONE?: string;
  ADDRESS_TYPE?: string;
  STREET_NAME?: string;
  STREET_NUMBER?: string;
  COMPLEMENT?: string;
  NEIGHBORHOOD?: string;
  CITY?: string;
  STATE?: string;
  COUNTRY?: string;
  ZIP_CODE?: string;
  REQUEST_BENEFITS_CARD?: string;
}

export type StatusMapSystemValues = "ACTIVE" | "ON_LEAVE" | "FIRED" | "BLOCKED";
export type EmployeeTypeMapSystemValues =
  | "STANDARD_CONTRACT"
  | "LEGAL_PERSON"
  | "FREELANCER"
  | "HOURLY_WAGE";

export interface AdditionalMaps {
  statusMap?: Record<string, StatusMapSystemValues>;
  employeeTypeMap?: Record<string, EmployeeTypeMapSystemValues>;
}

export interface Variables {
  key: string;
  columnsMap: ColumnMap;
  additionalMaps: AdditionalMaps;
  importType: string;
}

export const FILE_CONFIGURATION = gql`
  mutation employeeImportFileConfiguration(
    $key: String!
    $columnsMap: JSON!
    $additionalMaps: JSON
    $importType: String
  ) {
    employeeImportFileConfiguration(
      key: $key
      columnsMap: $columnsMap
      additionalMaps: $additionalMaps
      importType: $importType
    )
  }
`;

const useSetFileConfiguration = () => {
  return useMutation<null, Variables>(FILE_CONFIGURATION);
};

export { useSetFileConfiguration };
