import React, { useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { CustomPropsContext } from "../../contexts/CustomPropsContext";
import { EntryFlow } from "../../helpers";
import { Product } from "../../helpers/product";
import ImportHrEmployeeContainer from "../ImportHrEmployee/ImportHrEmployeeContainer";
import EmployeeImportsList from "../EmployeeImportsList/EmployeeImportsList";

export default function Router() {
  const { productBasePath, entryFlow, productType } = useContext(
    CustomPropsContext
  );

  const getImportTypeByProduct = () => {
    switch (productType) {
      case Product.PAYROLL:
        return <ImportHrEmployeeContainer />;
      case Product.PAYROLL_AND_BENEFITS:
        return <ImportHrEmployeeContainer />;
      case Product.BENEFITS:
        return <ImportHrEmployeeContainer />;
      default:
        return <div>Error</div>;
    }
  };

  const importFileRouter = (productBasePath: string) => (
    <>
      <Route exact path={`${productBasePath}/${EntryFlow.IMPORT_FILE}`}>
        {getImportTypeByProduct}
      </Route>
      <Route
        path={productBasePath}
        component={() => (
          <Redirect to={`${productBasePath}/${EntryFlow.IMPORT_FILE}`} />
        )}
      />
    </>
  );

  const adminRouter = (productBasePath: string) => (
    <>
      <Route
        exact
        path={`${productBasePath}/`}
        component={EmployeeImportsList}
      />
      <Route
        path={`${productBasePath}/upload`}
        component={ImportHrEmployeeContainer}
      />
      <Route
        path={productBasePath}
        component={() => <Redirect to={`${productBasePath}/`} />}
      />
    </>
  );

  return (
    <BrowserRouter>
      <Switch>
        {entryFlow === EntryFlow.DEFAULT
          ? adminRouter(productBasePath)
          : importFileRouter(productBasePath)}
      </Switch>
    </BrowserRouter>
  );
}
