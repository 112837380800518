import React from "react";

import { Typography } from "@creditas/typography";
import { Alert } from "@creditas/icons";

interface Props {
  errors: string[];
}

const ErrorsList = ({ errors }: Props) => (
  <ul style={{ listStyle: "none" }}>
    {errors.map((error, index) => (
      <li
        key={`${index}-${error}`}
        style={{
          display: "flex",
          verticalAlign: "top",
          marginBottom: "inherit"
        }}
      >
        <Alert.AlertTriangle color="danger" />
        <Typography
          style={{
            display: "inline-block",
            paddingLeft: 8
          }}
        >
          {error}
        </Typography>
      </li>
    ))}
  </ul>
);

export default ErrorsList;
