import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Step } from "../ImportHrEmployeeTypes";
import MatchValues from "../../../components/MatchValues/MatchValues";
import { useStep3Container } from "./Step3ContainerHook";
import { AnalyticsEvent, emitEvent } from "../../../helpers";
import { step3Analytics } from "../ImportHrEmployeeAnalyticsConstants";

interface Props {
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
  handleBackButton: (
    step: Step,
    setStep: Dispatch<SetStateAction<Step>>,
    event: AnalyticsEvent
  ) => void;
}

export const Step3Container: React.FC<Props> = ({
  step,
  setStep,
  handleBackButton
}: Props) => {
  const { submitValuesMapping, submitting } = useStep3Container(step, setStep);

  useEffect(() => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "LoadEmployeeImportStep3"
    });
  }, []);

  return (
    <>
      {step.systemValuesCellMappingNeeded !== null && (
        <MatchValues
          onBackButton={() => handleBackButton(step, setStep, step3Analytics)}
          uploadSystemValues={step.uploadSystemValues!}
          uploadFileAsJson={step.uploadFileAsJson!}
          indexColumnsMap={step.indexColumnsMap}
          systemValuesCellMappingNeeded={step.systemValuesCellMappingNeeded}
          loading={submitting}
          submitValuesMapping={submitValuesMapping}
        />
      )}
    </>
  );
};
