import React from "react";
import { Typography } from "@creditas/typography";

import { useTranslation } from "react-i18next";
import { ImportResultType } from "../../../../../services/bff/queries";
import ErrorsList from "../../../../../components/WithoutSideEffect/ErrorsList/ErrorsList";
import { Step } from "../../../../../pages/ImportHrEmployee/ImportHrEmployeeTypes";

interface Props {
  step: Step;
  onDownloadErrors: (id: string, type: ImportResultType) => void;
  fileLineErrorsData: string[];
  isButtonDisabled: boolean;
}

export const FeedbackErrorComponent: React.FC<Props> = ({
  step,
  onDownloadErrors,
  fileLineErrorsData,
  isButtonDisabled
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {!isButtonDisabled ? (
        <>
          <div
            style={{
              marginTop: 10
            }}
          >
            <Typography
              variant="textParagraph"
              style={{ display: "inline", fontSize: 20, lineHeight: 1.5 }}
            >
              {t(
                "hr.importEmployee.step4.feedbackDetails.processingError.uploadErrorDescription.text_1"
              )}
            </Typography>
            <Typography
              variant="textParagraph"
              style={{ display: "inline", fontSize: 20, lineHeight: 1.5 }}
            >
              {fileLineErrorsData.length}
            </Typography>
            {fileLineErrorsData.length > 1 ? (
              <Typography
                variant="textParagraph"
                style={{ display: "inline", fontSize: 20, lineHeight: 1.5 }}
              >
                {t(
                  "hr.importEmployee.step4.feedbackDetails.processingError.uploadErrorDescription.text_2_plural"
                )}
              </Typography>
            ) : (
              <Typography
                variant="textParagraph"
                style={{ display: "inline", fontSize: 20, lineHeight: 1.5 }}
              >
                {t(
                  "hr.importEmployee.step4.feedbackDetails.processingError.uploadErrorDescription.text_2_singular"
                )}
              </Typography>
            )}
          </div>
          <Typography
            variant="textParagraph"
            style={{ display: "inline", fontSize: 20, lineHeight: 1.5 }}
          >
            {t(
              "hr.importEmployee.step4.feedbackDetails.processingError.uploadErrorDescription.text_3"
            )}
          </Typography>
          <div style={{ paddingLeft: 2, display: "inline" }}>
            <Typography
              variant="textParagraph"
              style={{
                display: "inline",
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: 20,
                lineHeight: 1.5
              }}
              onClick={() =>
                step.uploadKey && onDownloadErrors(step.uploadKey, "ERROR")
              }
            >
              {t(
                "hr.importEmployee.step4.feedbackDetails.processingError.uploadErrorDescription.downloadLink"
              )}
            </Typography>
            <Typography
              variant="textParagraph"
              style={{ display: "inline", fontSize: 20, lineHeight: 1.5 }}
            >
              {t(
                "hr.importEmployee.step4.feedbackDetails.processingError.uploadErrorDescription.text_4"
              )}
            </Typography>
          </div>
        </>
      ) : (
        <Typography
          variant="textParagraph"
          style={{ display: "inline", fontSize: 20, lineHeight: 1.5 }}
        >
          {t(
            "hr.importEmployee.step4.feedbackDetails.processingError.uploadErrorDescription.text_5"
          )}
        </Typography>
      )}
      <ErrorsList errors={fileLineErrorsData} />
    </>
  );
};
