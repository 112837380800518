import { SplitFactory } from "@splitsoftware/splitio";
import SplitIO, { IClient } from "@splitsoftware/splitio/types/splitio";
import { useCallback, useEffect, useState } from "react";
import { envVars } from "../../envVars";
import { FEATURE_FLAG_ON } from "./feature-flag.constants";

type Attributes = {
  [attributeName: string]: string | number | boolean | Array<string | number>;
};

const SPLIT_IO_SDK_CONFIG = {
  core: {
    authorizationKey: envVars.SPLIT_API_KEY || "localhost",
    key: "Anonymous"
  }
};

class FeatureFlag {
  private splitFactory: SplitIO.ISDK;
  private client: IClient;
  private clientWithCompany: IClient | undefined;

  constructor() {
    this.splitFactory = SplitFactory(SPLIT_IO_SDK_CONFIG);
    this.client = this.splitFactory.client();
  }

  initClientWithCompany(companyGroupId: string) {
    this.clientWithCompany = this.splitFactory.client(companyGroupId);
  }

  async getTreatment(
    splitName: string,
    attributes?: Attributes
  ): Promise<string> {
    return this.client.ready().then(() => {
      return this.client.getTreatment(splitName, {
        ...attributes
      });
    });
  }

  async getTreatmentByCompany(
    splitName: string,
    attributes?: Attributes
  ): Promise<string> {
    if (this.clientWithCompany == undefined) {
      return "error: client not init";
    }
    return this.clientWithCompany.ready().then(() => {
      return this.clientWithCompany!.getTreatment(splitName, {
        ...attributes
      });
    });
  }
}

const featureFlag = new FeatureFlag();

export { featureFlag };

export const useFeatureFlagByCompany = (featureFlagName: string) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const featureFlagByCompanyHandler = useCallback(async () => {
    const flagResult = await featureFlag.getTreatmentByCompany(featureFlagName);

    setIsEnabled(flagResult === FEATURE_FLAG_ON);
  }, [featureFlagName]);

  useEffect(() => {
    featureFlagByCompanyHandler();
  }, [featureFlagByCompanyHandler]);

  return isEnabled;
};

export const useFeatureFlag = (featureFlagName: string) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const featureFlagHandler = useCallback(async () => {
    const flagResult = await featureFlag.getTreatment(featureFlagName);

    setIsEnabled(flagResult === FEATURE_FLAG_ON);
  }, [featureFlagName]);

  useEffect(() => {
    featureFlagHandler();
  }, [featureFlagHandler]);

  return isEnabled;
};
