import { gql, useLazyQuery } from "@creditas/apollo";

export const GET_COMPANIES_SETTINGS = gql`
  query getCompanySettings {
    companySettings {
      id
      groupName
    }
  }
`;

export const useGetCompaniesSettings = () =>
  useLazyQuery<Response>(GET_COMPANIES_SETTINGS);

interface CompanySettings {
  id: number;
  groupName: string;
}

interface Response {
  companySettings: CompanySettings[];
}
