const createTracer = (moduleName: string, action?: string): Promise<any> =>
  new Promise<void>(res => {
    const tracerName = action ? `${moduleName}/${action}` : moduleName;
    if (window.newrelic) {
      window.newrelic.interaction().createTracer(tracerName, e => res())();
    } else {
      res();
      console.warn("Your project doest not have a new relic loaded!");
    }
  });

export { createTracer };
