const CONFIGURATION = {
  hr: {
    authToken:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpbnRlcmZhY2UiOiJhZG1pbmlzdHJhdG9yIiwidHlwZSI6Ik1hbmFnZXIiLCJzdWIiOjEwLCJpc3MiOiJodHRwOi8vZGV4dGVyLXNhbmRib3guY3JlZGl0b28uaW4vYXBpL3YyL2F1dGgvYWRtaW4vbG9naW4iLCJpYXQiOjE2MDc1NTA5NDAsImV4cCI6MTc2MzA3MDk0MCwibmJmIjoxNjA3NTUwOTQwLCJqdGkiOiJWejJpeDMyU1VxRFg4b0RhIn0.PKu0QoLNU3PM_c8VIkXVzX_GcDlnXUrjYRzx5d0G9O4",
    companySettingsId: 34,
    authHrPortalToken: "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3IiwidXNlci1pZCI6NywidXNlci10eXBlIjoiTWFzdGVyIiwiY29tcGFueS1pZCI6MzMsInBlcm1pc3Npb25zIjpbInJlYWQtZW1wbG95ZWVzIiwicmVhZC1iZW5lZml0cy1lbXBsb3llZXMiXSwiaWF0IjoxNjYxMzU3ODgwLCJleHAiOjQyNTMzNTc4ODB9.KYavAiNyL3meHwVzgcC02_fnhvmeqvLtiHtxRtcJAgmIowvqq27nMXCjoE2rySWaedmggmuM2Q4ibZ1Kaj70G0GoZlzfYsyYzQ2aCvTuw_jGh1L_DLFQi3V3cSqRtYm95oZlPFZHs4fMr46rcaUPTnZN7DAFPNEsFJ3wwkiDZllb_IWy3m5tgyD3W6bH0Q6rtVTf2DBNR3SCvPXStqvn6OQPsQ1sdIShHdtV0bAJm6oorX4KvpbcnI5zyqpNvYZm1CgI1iGWfWGrme975tFIXvtXcva0jlPYVQLNzDi6__2slUgdmV_cJhNzfKHkGKvabK4ekwSqu4Pa8Zo5ZgSeEA",
    onExit: () => {}
  },
  hrPortal: {
    accessToken:
      "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMjgiLCJ1c2VyLWlkIjoxMjgsInVzZXItdHlwZSI6Ik1hbmFnZXIiLCJjb21wYW55LWlkIjoxMDAsImlhdCI6MTYyMTI0ODI1NiwiZXhwIjo0MjEzMjQ4MjU2fQ.fnju6tVyvasMRk2X69FvotqrOAuzaukKivJOm_xrEqKJCUwz3z5zdyXWjv40CsXvO5n9H8WkohT45uj3hX6Bqw"
  }
};

export { CONFIGURATION };
