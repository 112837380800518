import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  ButtonPrimaryTextOnly,
  IconDocumentContent12,
  Typography
} from "@creditas-ui/react";
import { ShapeIcons } from "@creditas-ui/shape-icons";
import { emitEvent } from "../../../../../helpers/analytics";
import { step5Analytics } from "../../../ImportHrEmployeeAnalyticsConstants";

interface Props {
  hasPendencies: boolean;
  onClick: () => void;
}

export const ImportProgressResultComponent: React.FC<Props> = ({
  hasPendencies,
  onClick
}) => {
  const { t } = useTranslation();

  {
    hasPendencies
      ? emitEvent({
          event: step5Analytics.importHasPendencies.event,
          category: step5Analytics.importHasPendencies.category,
          action: step5Analytics.importHasPendencies.action
        })
      : emitEvent({
          event: step5Analytics.importHasNoPendencies.event,
          category: step5Analytics.importHasNoPendencies.category,
          action: step5Analytics.importHasNoPendencies.action
        });
  }

  return (
    <>
      <Box gridColumn={{ xs: "", md: "2 / 3" }}>
        <ShapeIcons
          Icon={IconDocumentContent12}
          size="large"
          variant="success"
          alignSelf="flex-start"
        />
      </Box>
      <Box gridColumn={{ xs: "", md: "5 / 12" }}>
        <Typography variant="headingLgRegular" marginBottom={16}>
          {hasPendencies
            ? t("hr.importEmployee.step5.progressResult.hasPendencies.title")
            : t("hr.importEmployee.step5.progressResult.noPendencies.title")}
        </Typography>
        <Typography variant="bodyLgRegular">
          {hasPendencies
            ? t(
                "hr.importEmployee.step5.progressResult.hasPendencies.informationText"
              )
            : t(
                "hr.importEmployee.step5.progressResult.noPendencies.informationText"
              )}
        </Typography>
        <Box marginTop={56}>
          <ButtonPrimaryTextOnly size="large" onClick={onClick}>
            {hasPendencies
              ? t("hr.importEmployee.step5.goToPendenciesButton")
              : t(
                  "hr.importEmployee.step5.progressResult.noPendencies.goToColaboradoresButton"
                )}
          </ButtonPrimaryTextOnly>
        </Box>
      </Box>
    </>
  );
};
