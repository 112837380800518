import React, { Component } from "react";
import { emitPageView } from "./helpers/analytics";
import { CustomPropsContext } from "./contexts/CustomPropsContext";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name;
}

const withTracker = WrappedComponent => {
  const factory = class extends Component {
    static context = CustomPropsContext;
    static displayName: string;
    componentDidMount() {
      emitPageView(this.context);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
  factory.displayName = `withTracker(${getDisplayName(WrappedComponent)})`;
  return factory;
};

export default withTracker;
