import { useToaster } from "@creditas/toaster";
import { Product } from "../../../helpers/product";
import { useTranslation } from "react-i18next";
import {
  emitEvent,
  indexColumnsMapToColumnsMap,
  Sentry
} from "../../../helpers";
import {
  ColumnMap,
  useSetFileConfiguration
} from "../../../services/bff/mutations";
import {
  Response,
  useGetColumnsMap
} from "../../../services/bff/queries/useGetColumnsMap";
import {
  buildAlertsTableAnalyticsLabel,
  fileHasDuplicatedEmployeesWithSameCPFAndCNPJ,
  isColumnMapped
} from "../../../shared/helpers/helpers";
import { IndexColumnsMap } from "./Step2Types";
import { useCustomProps } from "../../../contexts/CustomPropsContext";
import { getImportTypeByProduct } from "../../../helpers/getImportTypeByProduct";
import { useState } from "react";
import {
  fileHasSalaryOrMarginAlerts,
  ModalEmployeeAlertsProps
} from "./components/modalAlertsTable/fileHasSalaryOrMarginAlerts";
import { SalaryType } from "../ImportHrEmployeeTypes";

interface Step2ContainerHook {
  submitColumnMapping: (indexColumnsMap: IndexColumnsMap) => void;
  confirmModalAlerts: () => void;
  data: Response | undefined;
  modalEmployeeAlertsProps: ModalEmployeeAlertsProps | undefined;
}

const registerSentryError = (error, step) => {
  if (error?.message) {
    const exception = new Error(error?.message);
    const extra = {
      flow: "benefits",
      request: "getColumnsMap",
      correlationId: step.uploadKey
    };
    Sentry.captureExceptionHint(exception, extra);
  }
};

const showToasterErrorOfDuplicatedEmployees = (
  step,
  indexColumnsMap,
  push,
  t
) => {
  const duplicatedEmployees = fileHasDuplicatedEmployeesWithSameCPFAndCNPJ(
    step.uploadFileAsJson!,
    indexColumnsMap.CPF,
    indexColumnsMap.CNPJ
  );

  if (duplicatedEmployees.length > 0) {
    const employees = duplicatedEmployees;
    const error = t("hr.globalErrorMessage.duplicatedCpfCnpj", {
      employees
    });

    push(error, {
      level: "danger",
      delay: -1
    });
  }
};

const showToasterErrorOfSelectSalaryOrMarginNotMapped = (
  indexColumnsMap,
  productType,
  step,
  push,
  t
) => {
  if (
    productType === Product.PAYROLL_AND_BENEFITS ||
    productType === Product.PAYROLL
  ) {
    if (
      !isColumnMapped(indexColumnsMap.SALARY) &&
      !isColumnMapped(indexColumnsMap.MARGIN)
    ) {
      let error;
      if (step.salaryType === SalaryType.BASE_SALARY) {
        error = t("hr.globalErrorMessage.unassociatedBaseSalaryAlert");
      } else if (step.salaryType === SalaryType.NET_SALARY) {
        error = t("hr.globalErrorMessage.unassociatedNetSalaryAlert");
      } else {
        error = t("hr.globalErrorMessage.unassociatedSalaryOrMarginAlert");
      }
      push(error, {
        level: "danger",
        delay: -1
      });
    }
  }
};

export const useStep2Container = (step, setStep): Step2ContainerHook => {
  const [setFileConfiguration] = useSetFileConfiguration();
  const { productType } = useCustomProps();

  const { data, error } = useGetColumnsMap();
  const { push } = useToaster();
  const { t } = useTranslation();

  const [columnMap, setColumnsMap] = useState<ColumnMap>();
  const [modalEmployeeAlertsProps, setModalAlertsData] = useState<
    ModalEmployeeAlertsProps
  >();

  registerSentryError(error?.message, step);

  const submitColumnMapping = (indexColumnsMap: IndexColumnsMap) => {
    showToasterErrorOfDuplicatedEmployees(step, indexColumnsMap, push, t);

    showToasterErrorOfSelectSalaryOrMarginNotMapped(
      indexColumnsMap,
      productType,
      step,
      push,
      t
    );

    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "ConfirmColumnsMapped"
    });

    const columnMap = indexColumnsMapToColumnsMap(
      indexColumnsMap,
      step.uploadFileAsJson!
    );

    const employeesWithAlerts = fileHasSalaryOrMarginAlerts(
      step.uploadFileAsJson!,
      indexColumnsMap
    );

    setStep(previousState => ({
      ...previousState,
      columnMap,
      indexColumnsMap
    }));

    if (employeesWithAlerts.employeeAlertData.length !== 0) {
      setModalAlertsData(employeesWithAlerts);
      setColumnsMap(columnMap);
      return;
    }

    nextStep(columnMap);
  };

  const confirmModalAlerts = () => {
    modalEmployeeAlertsProps &&
      emitEvent({
        event: "gaCreditasEvent",
        category: "EmployeeImport",
        action: "ConfirmAlertsTableModal",
        label: buildAlertsTableAnalyticsLabel(modalEmployeeAlertsProps)
      });
    nextStep(columnMap!);
  };

  const nextStep = (columnMap: ColumnMap) => {
    const moveToValuesMap = systemValuesCellMappingNeeded => {
      setStep(previousState => ({
        ...previousState,
        current: previousState.current + 1,
        systemValuesCellMappingNeeded: systemValuesCellMappingNeeded
      }));
    };

    const moveToFeedback = () => {
      setFileConfiguration({
        variables: {
          key: step.uploadKey!,
          columnsMap: columnMap,
          additionalMaps: {},
          importType: getImportTypeByProduct(productType)
        }
      })
        .then(() => {
          setStep(previousState => ({
            ...previousState,
            current: previousState.current + 2
          }));
        })
        .catch(error => {
          const exception = new Error(error);
          const extra = {
            flow: "benefits",
            request: "setFileConfiguration",
            data: "columnsMap",
            correlationId: step.uploadKey
          };
          Sentry.captureExceptionHint(exception, extra);
        });
    };

    const systemValuesWithCellMap: string[] = [];
    step.uploadSystemValues.forEach(column => {
      if (column.valuesMap) {
        systemValuesWithCellMap.push(column.key);
      }
    });
    const systemValuesCellMappingNeeded: string[] = [];
    systemValuesWithCellMap.forEach(value => {
      if (value in columnMap) {
        systemValuesCellMappingNeeded.push(value);
      }
    });
    const isValuesMapNeeded = systemValuesCellMappingNeeded.length > 0;
    isValuesMapNeeded
      ? moveToValuesMap(systemValuesCellMappingNeeded)
      : moveToFeedback();
  };

  return {
    submitColumnMapping,
    confirmModalAlerts,
    data,
    modalEmployeeAlertsProps
  };
};
