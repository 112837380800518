import { Typography } from "@creditas-ui/react";
import React from "react";
import {
  HrProgressBarContainer,
  HrProgressBarFiller
} from "./ProgressBarComponent.styles";

interface Props {
  percentage: number;
  progressBarText: string;
}

const HrProgressBarComponent = ({ percentage, progressBarText }: Props) => {
  return (
    <>
      <HrProgressBarContainer data-testid="hr-progress-bar">
        <HrProgressBarFiller percentage={percentage} />
      </HrProgressBarContainer>
      <Typography variant="bodyMdBold">{progressBarText}</Typography>
    </>
  );
};

export default HrProgressBarComponent;
