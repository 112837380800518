import React, { useContext } from "react";
import { AppCustomProps, defaultCustomProps } from "..";

export const CustomPropsContext = React.createContext<AppCustomProps>(
  defaultCustomProps
);

export const CustomPropsProvider = CustomPropsContext.Provider;

export const useCustomProps = () => {
  return useContext(CustomPropsContext);
};
