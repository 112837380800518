import { gql, useLazyQuery } from "@creditas/apollo";

export const EMPLOYEE_DIFF_FROM_LAST_IMPORT = gql`
  query getEmployeeDiffFromLastImport($sourceId: ID!, $groupId: String) {
    employeeDiffFromLastImport(sourceId: $sourceId, groupId: $groupId) {
      diff
      total
      active
      pending
      fired {
        id
        name
        mainDocument {
          code
          type
        }
      }
      absent {
        id
        name
        mainDocument {
          code
          type
        }
      }
    }
  }
`;

export interface EmployeeImportDiffWithDocument {
  id: string;
  name: string;
  mainDocument: {
    code: string;
    type: string;
  };
}

export interface ResponseDiffFromLastImport {
  employeeDiffFromLastImport: {
    diff: number;
    total: number;
    active: number;
    pending: number;
    fired: EmployeeImportDiffWithDocument[];
    absent: EmployeeImportDiffWithDocument[];
  };
}

interface Variables {
  sourceId: string | undefined;
  groupId: string;
}

export const useGetEmployeeDiffFromLastImport = (
  sourceId: string | undefined,
  groupId: string
) => {
  return useLazyQuery<ResponseDiffFromLastImport, Variables>(
    EMPLOYEE_DIFF_FROM_LAST_IMPORT,
    {
      variables: { sourceId, groupId }
    }
  );
};
