import { MouseEvent } from "react";

const getGoogleAnalytics = () => window.ga || function(...args) {};
const getDataLayer = () => window.dataLayer || (window.dataLayer = []);

export interface AnalyticsEvent {
  action: string;
  label?: any;
  category: string;
  event: string;
  value?: any;
  [name: string]: Object;
}

export interface AnalyticsPageView {
  path: string;
  title: string;
}

export function emitEvent(analyticsEvent: AnalyticsEvent) {
  const stringLabel =
    typeof analyticsEvent.label === "string"
      ? analyticsEvent.label
      : JSON.stringify(analyticsEvent.label);
  const label = analyticsEvent.label ? stringLabel : null;

  getDataLayer().push({
    ...analyticsEvent,
    ...(label ? { label } : {})
  });
}

export function emitPageView(pageView?: AnalyticsPageView) {
  const event = {
    event: "creditas.pageview",
    title: pageView?.title || document.title,
    path: pageView?.path || window.location.href
  };
  getDataLayer().push(event);
  getGoogleAnalytics()("send", "pageview", event.path);
}

export function emitEventByAttribute(mouseEvent: MouseEvent<HTMLElement>) {
  const action = mouseEvent.currentTarget.dataset.eventAction as string;
  const event = "gaCreditasPayrollAdmin";
  const category = "employeeImport";
  emitEvent({ event, category, action });
}
