import { SalaryType } from "../../pages/ImportHrEmployee/ImportHrEmployeeTypes";

interface IProps {
  url: string;
  groupId?: string;
  hrPortalToken?: string;
}

interface EmployeeImportProgressSummary {
  total: number;
  cancelled: number;
  confirmed: number;
  error: number;
  waiting: number;
  withoutModification: number;
  withPendencies: number;
}

const HR_PORTAL_ACCESS_TOKEN = "hr_portal_access_token";

const getHrPortalToken = (): string =>
  localStorage.getItem(HR_PORTAL_ACCESS_TOKEN) || "";

export const getCompanyDocumentCodesInGroup = async ({
  url
}: IProps): Promise<string[]> => {
  return fetch(`${url}/companies`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getHrPortalToken()}`
    }
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Error getting CNPJs.");
    }
  });
};

export const getCompanyDocumentCodesInGroupByGroupId = async ({
  url,
  groupId,
  hrPortalToken
}: IProps): Promise<string[]> => {
  return fetch(`${url}/commercial-group/${groupId}/companies`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${hrPortalToken}`
    }
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Error getting CNPJs for given group id.");
    }
  });
};

export const getImportProgressSummary = async ({
  url,
  sourceId
}): Promise<EmployeeImportProgressSummary> => {
  return fetch(`${url}/importations/${sourceId}/progress`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getHrPortalToken()}`
    }
  }).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(
        "Error getting import progress summary for given source id"
      );
    }
  });
};

export const getGroupSalaryType = async ({
  url,
  groupId
}: IProps): Promise<SalaryType> => {
  return fetch(`${url}/commercial-group/${groupId}/salary-type`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getHrPortalToken()}`
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error("Error getting group's salary type.");
      }
      return response.json();
    })
    .then(response => {
      return response.salaryType;
    });
};
