import React from "react";
import { Typography } from "@creditas/typography";
import FlexItem from "../../../../../components/Styled/FlexItem";
import {
  StyledContainer,
  StyledFlex
} from "./UploadSpreadsheetComponent.styles";
import { useTranslation } from "react-i18next";
import Quote from "../Quote/Quote";
import { Product } from "../../../../../helpers/product";
import { UPLOAD_SPREADSHEET_TEXTS } from "./UploadSpreadsheetComponentTexts";
import { SalaryType } from "../../../ImportHrEmployeeTypes";
import { useCustomProps } from "../../../../../contexts/CustomPropsContext";
import { Flex as FlexDS, IconWarning_2 } from "@creditas-ui/react";
import UploadFile from "../../../../../components/UploadFile/UploadFile";
import { UserType } from "../../../../../interfaces/userType";

interface Props {
  onSend(): Promise<void>;
  onDownload(): void;
  setFile(file: File): void;
  setCompanySettingsIdSelectedByMaster(companySettingId: string): void;
  companySettingsIdSelectedByMaster: string | undefined;
  companySettingsIdOfImportation: number | undefined;
  productType: Product;
  salaryType: SalaryType | undefined;
}

const UploadSpreadsheetComponent = ({
  onSend,
  onDownload,
  setFile,
  setCompanySettingsIdSelectedByMaster,
  companySettingsIdSelectedByMaster,
  companySettingsIdOfImportation,
  productType,
  salaryType
}: Props) => {
  const { t } = useTranslation();
  const { userType } = useCustomProps();

  const texts = () => {
    switch (productType) {
      case Product.PAYROLL:
        return UPLOAD_SPREADSHEET_TEXTS.onlyPayroll;
      case Product.PAYROLL_AND_BENEFITS:
        return UPLOAD_SPREADSHEET_TEXTS.payrollAndBenefits;
      case Product.BENEFITS:
        return UPLOAD_SPREADSHEET_TEXTS.onlyBenefits;
      default:
        return UPLOAD_SPREADSHEET_TEXTS.error;
    }
  };

  const salaryTypeTexts = () => {
    if (
      (productType === Product.PAYROLL ||
        productType === Product.PAYROLL_AND_BENEFITS) &&
      userType !== UserType.MASTER
    ) {
      switch (salaryType) {
        case SalaryType.NET_SALARY:
          return UPLOAD_SPREADSHEET_TEXTS.salaryTypeInformation
            .netSalaryStep1Text;
        case SalaryType.BASE_SALARY:
          return UPLOAD_SPREADSHEET_TEXTS.salaryTypeInformation
            .baseSalaryStep1Text;
        case SalaryType.PRE_ESTABLISHED_MARGIN:
          return UPLOAD_SPREADSHEET_TEXTS.salaryTypeInformation
            .preEstablishedMarginSalaryStep1Text;
        default:
          return "";
      }
    }
  };

  const {
    title,
    subtitle,
    textMain,
    textStep1,
    textStep2,
    textStep3,
    information
  } = texts();

  const salaryTypeText = salaryTypeTexts();

  return (
    <StyledContainer maxWidth="lg">
      <StyledFlex>
        <FlexItem basis="60%" style={{ paddingRight: 40 }}>
          <Typography variant="h4">{t(title)}</Typography>
          <Typography style={{ marginTop: 54 }} variant="h5">
            {t(subtitle)}
          </Typography>
          <Typography variant="paragraph">{t(textMain)}</Typography>
          <Typography style={{ marginTop: 24 }} variant="paragraph">
            {t(textStep1)}
          </Typography>
          <Typography style={{ marginTop: 24 }} variant="paragraph">
            {t(textStep2)}
          </Typography>
          <Typography style={{ marginTop: 24 }} variant="paragraph">
            {t(textStep3)}
          </Typography>
          {salaryTypeText && userType !== UserType.MASTER && (
            <FlexDS
              style={{
                marginTop: 56,
                paddingTop: 56,
                borderTop: "1px solid #EDF0EF"
              }}
            >
              <IconWarning_2 color="#E37900" size={25} />
              <Typography
                style={{ width: "100%", marginLeft: 18 }}
                variant="paragraph"
              >
                {t(salaryTypeText)}
              </Typography>
            </FlexDS>
          )}
          {information && <Quote text={t(information)} />}
        </FlexItem>
        <FlexItem basis="40%">
          <UploadFile
            onDownload={onDownload}
            onSend={onSend}
            setFile={setFile}
            setCompanySettingsIdSelectedByMaster={
              setCompanySettingsIdSelectedByMaster
            }
            companySettingsIdSelectedByMaster={
              companySettingsIdSelectedByMaster
            }
            companySettingsIdOfImportation={companySettingsIdOfImportation}
          />
        </FlexItem>
      </StyledFlex>
    </StyledContainer>
  );
};

export default UploadSpreadsheetComponent;
