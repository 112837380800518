import { gql, useMutation } from "@creditas/apollo";

export type EMPLOYEE_CONFIRM_IMPORT_ACTION = "KEEP" | "REMOVE";

export interface EmployeeConfirmImportPayload {
  importAction: EMPLOYEE_CONFIRM_IMPORT_ACTION | undefined;
}

export interface EmployeeConfirmImportPayloadParams {
  sourceId: string | null;
  payload: EmployeeConfirmImportPayload | null;
  groupId: string;
}

const EMPLOYEE_CONFIRM_IMPORT = gql`
  mutation employeeConfirmImport(
    $sourceId: ID!
    $payload: ConfirmImportPayload!
    $groupId: String
  ) {
    employeeConfirmImport(
      sourceId: $sourceId
      payload: $payload
      groupId: $groupId
    )
  }
`;

const useEmployeeConfirmImport = () =>
  useMutation<void, EmployeeConfirmImportPayloadParams>(
    EMPLOYEE_CONFIRM_IMPORT
  );

export { useEmployeeConfirmImport };
