import { gql, useMutation } from "@creditas/apollo";
import { DexterColumns, DexterImport, LastUsedSettings } from "interfaces";

export const DEXTER_UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!, $companySettingsId: Int) {
    uploadEmployeesSpreadsheet(
      file: $file
      companySettingsId: $companySettingsId
    ) {
      import {
        id
      }
      columns
      preview
    }
  }
`;

export const useDexterUploadFile = () => {
  return useMutation<UploadResponse, Variables>(DEXTER_UPLOAD_FILE);
};

interface Variables {
  file: File;
  companySettingsId?: number;
}

interface UploadResponse {
  uploadImporterFile: string;
  uploadEmployeesSpreadsheet: {
    import: DexterImport;
    columns: DexterColumns;
    preview: Array<string[]>;
    last_used_settings: LastUsedSettings;
  };
}
