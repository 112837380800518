import { IndexColumnsMap } from "../../Step2Types";
import { EmployeeAlertData } from "./ModalAlertsTableTypes";

export interface ModalEmployeeAlertsProps {
  employeeAlertData: EmployeeAlertData[];
  isMarginHigh?: boolean;
  isSalaryHigh?: boolean;
  isMarginHigherThanSalary?: boolean;
}

const getValueAsNumberFromFile = (fileAsJson, columnIndex, rowIndex) => {
  return columnIndex && fileAsJson[rowIndex][columnIndex]
    ? Number(
        fileAsJson[rowIndex][columnIndex] &&
          fileAsJson[rowIndex][columnIndex]
            .replace(",", "")
            .replace("R$", "")
            .trim()
      )
    : undefined;
};

const isSalaryHigherThanThreshold = salary => {
  const HIGH_SALARY_THRESHOLD = 20000;
  if (salary !== undefined && salary > HIGH_SALARY_THRESHOLD) {
    return true;
  }
};

const isMarginHigherThanThreshold = margin => {
  const HIGH_MARGIN_THRESHOLD = 8000;
  if (margin !== undefined && margin > HIGH_MARGIN_THRESHOLD) {
    return true;
  }
};

const isMarginHigherThanMargin = (salary, margin) => {
  if (salary !== undefined && margin !== undefined && margin > salary) {
    return true;
  }
};

const getEmployeeRelevantData = (fileAsJson, indexColumnsMap, rowIndex) => {
  const employeeAlertData = {
    name:
      indexColumnsMap.NAME !== undefined
        ? fileAsJson[rowIndex][indexColumnsMap.NAME]
        : undefined,
    cpf:
      indexColumnsMap.CPF !== undefined
        ? fileAsJson[rowIndex][indexColumnsMap.CPF]
        : undefined,
    cnpj:
      indexColumnsMap.CNPJ !== undefined
        ? fileAsJson[rowIndex][indexColumnsMap.CNPJ]
        : undefined,
    salary:
      indexColumnsMap.SALARY !== undefined
        ? fileAsJson[rowIndex][indexColumnsMap.SALARY]
        : undefined,
    margin:
      indexColumnsMap.MARGIN !== undefined
        ? fileAsJson[rowIndex][indexColumnsMap.MARGIN]
        : undefined
  };
  return employeeAlertData;
};

export const fileHasSalaryOrMarginAlerts = (
  fileAsJson: string[][],
  indexColumnsMap: IndexColumnsMap
): ModalEmployeeAlertsProps => {
  const employeesWithAlerts: EmployeeAlertData[] = [];
  let isMarginHigh: boolean | undefined = undefined;
  let isSalaryHigh: boolean | undefined = undefined;
  let isMarginHigherThanSalary: boolean | undefined = undefined;
  for (let index = 1; index < fileAsJson.length; index++) {
    let employeeHasError: boolean | undefined = undefined;
    const salary = getValueAsNumberFromFile(
      fileAsJson,
      indexColumnsMap.SALARY,
      index
    );
    const margin = getValueAsNumberFromFile(
      fileAsJson,
      indexColumnsMap.MARGIN,
      index
    );
    const isSalaryHighEmployee = isSalaryHigherThanThreshold(salary);
    if (isSalaryHighEmployee) isSalaryHigh = true;
    const isMarginHighEmployee = isMarginHigherThanThreshold(margin);
    if (isMarginHighEmployee) isMarginHigh = true;
    const isMarginHigherThanSalaryEmployee = isMarginHigherThanMargin(
      salary,
      margin
    );
    if (isMarginHigherThanSalaryEmployee) isMarginHigherThanSalary = true;

    employeeHasError =
      isSalaryHighEmployee ||
      isMarginHighEmployee ||
      isMarginHigherThanSalaryEmployee;

    if (employeeHasError) {
      const employeeAlertData = getEmployeeRelevantData(
        fileAsJson,
        indexColumnsMap,
        index
      );
      employeesWithAlerts.push(employeeAlertData);
    }
  }
  return {
    employeeAlertData: employeesWithAlerts,
    isMarginHigh: isMarginHigh,
    isSalaryHigh: isSalaryHigh,
    isMarginHigherThanSalary: isMarginHigherThanSalary
  };
};
