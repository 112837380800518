import { gql, useLazyQuery } from "@creditas/apollo";

const FILE_IMPORTER_SUMMARY = gql`
  query getFileImportSummary($id: ID!, $groupId: String) {
    getFileImportSummary(id: $id, groupId: $groupId) {
      fileId
      importStatus
      summary {
        SUCCESS
        ERROR
        SCHEDULED
      }
    }
  }
`;

export interface Variables {
  id: string;
  groupId: string;
}

export interface SummaryResponse {
  getFileImportSummary: FileImportSummary;
}

export type FileImportSummary = {
  fileId: string;
  importStatus: "WAITING" | "IN_PROGRESS" | "ERROR" | "SUCCESS";
  summary: {
    SUCCESS?: number;
    ERROR?: number;
    SCHEDULED?: number;
  };
};

export const useGetFileImportSummary = () => {
  return useLazyQuery<SummaryResponse, Variables>(FILE_IMPORTER_SUMMARY, {
    pollInterval: 4000
  });
};
