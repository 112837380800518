import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  ButtonPrimaryTextOnly,
  IconDocumentContent20,
  Typography
} from "@creditas-ui/react";
import { ShapeIcons } from "@creditas-ui/shape-icons";
import { SummaryStatus } from "../../../../../helpers/summaryStatus";
import HrProgressBarComponent from "../ProgressBarComponent/ProgressBarComponent";

export interface ImportProgressSummary {
  total: number;
  processed: number;
  waitingPreviousValue?: number;
  hasPendencies: boolean;
  status: SummaryStatus;
}
interface Props {
  summary: ImportProgressSummary;
  progressBarText: string;
  onClick: () => void;
}

export const ImportProgressSummaryComponent: React.FC<Props> = ({
  summary,
  progressBarText,
  onClick
}) => {
  const { t } = useTranslation();

  const percentage =
    summary.status === SummaryStatus.INIT
      ? 0
      : (summary.processed / summary.total) * 100;

  return (
    <>
      <Box gridColumn={{ xs: "", md: "2 / 3" }}>
        <ShapeIcons
          Icon={IconDocumentContent20}
          size="large"
          variant="success"
          alignSelf="flex-start"
        />
      </Box>
      <Box gridColumn={{ xs: "", md: "5 / 12" }}>
        <Typography variant="headingLgRegular" marginBottom={16}>
          {t("hr.importEmployee.step5.progressSummary.title")}
        </Typography>
        <Typography variant="bodyLgRegular" marginBottom={10}>
          {t("hr.importEmployee.step5.progressSummary.informationText.text_1")}
        </Typography>
        <Typography variant="bodyLgRegular" marginBottom={10}>
          {t("hr.importEmployee.step5.progressSummary.informationText.text_2")}
        </Typography>
        <Box display="flex" flexDirection={"column"}>
          <Typography variant="bodyLgRegular">
            {t(
              "hr.importEmployee.step5.progressSummary.informationText.text_3"
            )}
          </Typography>
          <Box className="progress-bar-wrapper" marginTop={80}>
            <HrProgressBarComponent
              percentage={percentage}
              progressBarText={progressBarText}
            />
          </Box>
          {summary.hasPendencies && (
            <Box marginTop={80}>
              <ButtonPrimaryTextOnly size="large" onClick={onClick}>
                {t("hr.importEmployee.step5.goToPendenciesButton")}
              </ButtonPrimaryTextOnly>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
