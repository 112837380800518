import { styled } from "@creditas/stylitas";

const Container = styled.div`
  border-left: 2px solid #3d9c96;
  border-radius: 2px;
  padding: 0.5em 20px;
  margin-top: 48px;
  line-height: 24px;
`;

export default Container;
