import { styled } from "@creditas/stylitas";
import { Container } from "@creditas/layout";
import { Flex } from "@creditas-ui/react";

export const StyledContainer = styled(Container)`
  display: flex;

  p:not div p {
    margin-top: 30px;
  }

  li {
    list-style-position: inside;
  }
`;

export const StyledFlex = styled(Flex)`
  @media (max-width: 1040px) {
    flex-wrap: wrap;
  }

  margin: 0 -8px;
`;
