import { gql, useMutation } from "@creditas/apollo";
import { MainDocument } from ".";
import { EmployeeImportDiffWithDocument } from "../queries";

export interface EmployeesWithContractsParams {
  employees: EmployeeImportDiffWithDocument[];
}

export interface EmployeesWithContractsPayload {
  payload: EmployeesWithContractsParams;
}

const EMPLOYEE_WITH_CONTRACTS = gql`
  mutation employeeWithContracts($payload: EmployeesWithContractsPayload!) {
    employeeWithContracts(payload: $payload) {
      employees {
        name
        mainDocument {
          code
          type
        }
      }
    }
  }
`;

export interface EmployeesWithContractsResponse {
  employeeWithContracts: {
    employees: [
      {
        name: string;
        mainDocument: MainDocument;
      }
    ];
  };
}

const useEmployeeWithContracts = () =>
  useMutation<EmployeesWithContractsResponse, EmployeesWithContractsPayload>(
    EMPLOYEE_WITH_CONTRACTS
  );

export { useEmployeeWithContracts };
