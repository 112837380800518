import { styled, css, utilities } from "@creditas/stylitas";
import { Button } from "@creditas/button";

const styledSecondaryButton = ({ theme }) => css`
  border-color: #49e295;
  color: #000000;

  :hover {
    border-color: #49e295;
    background-color: ${utilities.palette.color(theme, "neutral01")};
    color: #000000;
    box-shadow: 0px 16px 12px -8px rgb(0 0 0 / 16%),
      0px 16px 8px -12px rgb(0 0 0 / 12%);
    transition: box-shadow 0.3s ease-in-out;
  }
`;

export const StyledSecondaryButton = styled(Button)`
  ${styledSecondaryButton}
`;
