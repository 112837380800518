import { gql, useLazyQuery } from "@creditas/apollo";

const IMPORT_FILE_RESULT = gql`
  query getImportFileResult($input: FileResultInput) {
    getImportFileResult(input: $input) {
      url
    }
  }
`;
export type ImportResultType = "SUCCESS" | "ERROR";

export interface GetFileResultVariables {
  input: {
    id: string;
    type: ImportResultType;
  };
}

export interface GetFileResultResponse {
  getImportFileResult: {
    url: string;
  };
}

export const useGetFileResult = () => {
  return useLazyQuery<GetFileResultResponse, GetFileResultVariables>(
    IMPORT_FILE_RESULT,
    {
      onCompleted(data) {
        window.location.replace(data.getImportFileResult.url);
      }
    }
  );
};
