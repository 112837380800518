import { SystemValue } from "components/MatchColumn/interfaces";
export const BENEFITS_EMPLOYEE_SYSTEM_VALUES: SystemValue[] = [
  {
    key: "NAME",
    label: "Nome completo",
    required: true
  },
  {
    key: "BIRTH_DATE",
    label: "Data de nascimento",
    required: true
  },
  {
    key: "CPF",
    label: "CPF",
    required: true
  },
  {
    key: "CNPJ",
    label: "CNPJ",
    required: true
  },
  {
    key: "ADDRESS_TYPE",
    label: "Tipo de endereço",
    required: true
  },
  {
    key: "STREET_NAME",
    label: "Rua",
    required: true
  },
  {
    key: "STREET_NUMBER",
    label: "Número",
    required: true
  },
  {
    key: "COMPLEMENT",
    label: "Complemento",
    required: true
  },
  {
    key: "NEIGHBORHOOD",
    label: "Bairro",
    required: false
  },
  {
    key: "CITY",
    label: "Cidade",
    required: true
  },
  {
    key: "STATE",
    label: "Estado - Sigla",
    required: true
  },
  {
    key: "COUNTRY",
    label: "Pais",
    required: true
  },
  {
    key: "ZIP_CODE",
    label: "Cep",
    required: true
  },
  {
    key: "TELEPHONE_TYPE",
    label: "Tipo de número de telefone",
    required: false
  },
  {
    key: "COUNTRY_CODE",
    label: "Código do pais",
    required: false
  },
  {
    key: "AREA_CODE",
    label: "Código da área",
    required: false
  },
  {
    key: "TELEPHONE",
    label: "Numero de telefone/celular",
    required: false
  },
  {
    key: "EMAIL",
    label: "Email pessoal",
    required: false
  },
  {
    key: "ADMISSION_DATE",
    label: "Data de admissão",
    required: true
  },
  {
    key: "REQUEST_BENEFITS_CARD",
    label: "Solicitar cartão de benefícios",
    required: true
  },
  {
    key: "STATUS",
    label: "Situação",
    required: false,
    valuesMap: {
      name: "statusMap",
      values: [
        {
          text: "Ativo",
          value: "ACTIVE"
        },
        {
          text: "Desligado",
          value: "FIRED"
        },
        {
          text: "Afastado",
          value: "ON_LEAVE"
        },
        {
          text: "Bloqueado",
          value: "BLOCKED"
        }
      ]
    }
  }
];
