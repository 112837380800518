import { gql, useMutation } from "@creditas/apollo";

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!, $groupId: Int) {
    uploadImporterFile(file: $file, groupId: $groupId)
  }
`;

export const useUploadFile = () => {
  return useMutation<Response, Variables>(UPLOAD_FILE);
};

interface Response {
  uploadImporterFile: string;
}

interface Variables {
  file: File;
  groupId?: number;
}
