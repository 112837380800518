interface ICardCreationRequest {
  url: string | undefined;
  sourceId: string | undefined;
}

const HR_PORTAL_ACCESS_TOKEN = "hr_portal_access_token";

const getHrPortalToken = (): string =>
  localStorage.getItem(HR_PORTAL_ACCESS_TOKEN) || "";

export const doCardCreationRequest = async ({
  url,
  sourceId
}: ICardCreationRequest) => {
  return fetch(`${url}/card-creation-request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getHrPortalToken()}`
    },
    body: JSON.stringify({ sourceId })
  }).then(response => {
    if (!response.ok) {
      throw new Error("Error server connection");
    } else {
      return response.ok;
    }
  });
};
