import { gql, useMutation } from "@creditas/apollo";

export interface EmployeeCancelImportParams {
  $sourceId: string;
}

export const EMPLOYEE_CANCEL_IMPORT = gql`
  mutation cancelPendingImport($sourceId: ID!) {
    cancelPendingImport(sourceId: $sourceId)
  }
`;

const useEmployeeCancelImport = (sourceId: string) =>
  useMutation<null, { sourceId: string }>(EMPLOYEE_CANCEL_IMPORT, {
    variables: { sourceId }
  });

export { useEmployeeCancelImport };
