import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Step } from "../ImportHrEmployeeTypes";
import { useStep2Container } from "./Step2ContainerHook";
import MatchColumn from "../../../components/MatchColumn/MatchColumn";
import { AnalyticsEvent, emitEvent } from "../../../helpers";
import { ModalAlertsTableComponent } from "./components/modalAlertsTable/ModalAlertsTableComponent";
import { buildAlertsTableAnalyticsLabel } from "../../../shared/helpers/helpers";
import { step2Analytics } from "../ImportHrEmployeeAnalyticsConstants";
interface Props {
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
  handleBackButton: (
    step: Step,
    setStep: Dispatch<SetStateAction<Step>>,
    event: AnalyticsEvent
  ) => void;
}

export const Step2Container: React.FC<Props> = ({
  step,
  setStep,
  handleBackButton
}: Props) => {
  const {
    submitColumnMapping,
    confirmModalAlerts,
    data,
    modalEmployeeAlertsProps
  } = useStep2Container(step, setStep);
  useEffect(() => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "LoadEmployeeImportStep2"
    });
  }, []);

  useEffect(() => {
    modalEmployeeAlertsProps &&
      emitEvent({
        event: "gaCreditasEvent",
        category: "EmployeeImport",
        action: "DisplayAlertsTableModal",
        label: buildAlertsTableAnalyticsLabel(modalEmployeeAlertsProps)
      });
  }, [modalEmployeeAlertsProps]);

  return (
    <>
      <MatchColumn
        onBackButton={() => handleBackButton(step, setStep, step2Analytics)}
        preview={step.uploadPreview!}
        systemValues={step.uploadSystemValues!}
        loading={false}
        submitColumnMapping={submitColumnMapping}
        lastColumnMap={data?.employeeImportGetColumnsMap.columnsMap}
      />
      {modalEmployeeAlertsProps && (
        <ModalAlertsTableComponent
          open={true}
          employees={modalEmployeeAlertsProps.employeeAlertData}
          isMarginHigh={modalEmployeeAlertsProps.isMarginHigh}
          isSalaryHigh={modalEmployeeAlertsProps.isSalaryHigh}
          isMarginHigherThanSalary={
            modalEmployeeAlertsProps.isMarginHigherThanSalary
          }
          onConfirm={confirmModalAlerts}
          onCancel={() =>
            handleBackButton(step, setStep, {
              event: "gaCreditasEvent",
              category: "EmployeeImport",
              action: "CancelAlertsTableModal",
              label: buildAlertsTableAnalyticsLabel(modalEmployeeAlertsProps)
            })
          }
        />
      )}
    </>
  );
};
