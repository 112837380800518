import { ModalEmployeeAlertsProps } from "pages/ImportHrEmployee/step2/components/modalAlertsTable/fileHasSalaryOrMarginAlerts";

export const checkIfFileHasRowsBesidesHeader = (fileAsJson: string[][]) => {
  if (fileAsJson.length <= 1)
    throw new Error(
      "Não há linhas na planilha. Insira as linhas e preencha com os dados solicitados."
    );
};

export const fileHasDuplicatedCPFsAndCNPJs = (
  fileAsJson: string[][],
  cpfColumnIndex,
  cnpjColumnIndex
): boolean => {
  let arrayOfCpfsCnpjs: string[] = [];
  for (let index = 1; index < fileAsJson.length; index++) {
    let cpf = fileAsJson[index][cpfColumnIndex]
      ? fileAsJson[index][cpfColumnIndex]
      : "";
    let cnpj = fileAsJson[index][cnpjColumnIndex]
      ? fileAsJson[index][cnpjColumnIndex]
      : "";

    if (cpf !== "" && !checkString(cpf) && cnpj !== "" && !checkString(cnpj)) {
      arrayOfCpfsCnpjs.push(
        `${cpf.replace(/\D/g, "")}${cnpj.replace(/\D/g, "")}`
      );
    }
  }

  if (new Set(arrayOfCpfsCnpjs).size !== arrayOfCpfsCnpjs.length) return true;
  return false;
};

const checkString = (string: string) => /[a-zA-Z]/.test(string);

export const fileHasDuplicatedEmployeesWithSameCPFAndCNPJ = (
  fileAsJson: string[][],
  cpfColumnIndex?: number,
  cnpjColumnIndex?: number
): string[] => {
  const arrayOfCpfsCnpjs: string[] = [];
  const duplicatedCpfsCnpjs: string[] = [];

  for (let index = 1; index < fileAsJson.length; index++) {
    const cpf = cpfColumnIndex && fileAsJson[index][cpfColumnIndex];
    const cnpj = cnpjColumnIndex && fileAsJson[index][cnpjColumnIndex];

    if (cpf && !checkString(cpf) && cnpj && !checkString(cnpj)) {
      const cpfCnpj = `${cpf.replace(/\D/g, "")}${cnpj.replace(/\D/g, "")}`;

      if (arrayOfCpfsCnpjs.includes(cpfCnpj)) {
        duplicatedCpfsCnpjs.push(`[ CPF: ${cpf}, CNPJ: ${cnpj} ]`);
      } else {
        arrayOfCpfsCnpjs.push(cpfCnpj);
      }
    }
  }

  return duplicatedCpfsCnpjs;
};

export const isColumnMapped = (columnIndex): boolean =>
  !isNaN(Number(columnIndex)) && Number(columnIndex) >= 0;

export const formattedCnpjToRaw = (formattedCnpj: string) => {
  return formattedCnpj && formattedCnpj.replace(/\D/g, "");
};

export const rawCnpjToFormatted = (rawCnpj: string) => {
  return (
    rawCnpj &&
    rawCnpj
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
  );
};

export const buildAlertsTableAnalyticsLabel = (
  modalEmployeeAlertsProps: ModalEmployeeAlertsProps
) => {
  const warningList: string[] = [];
  if (modalEmployeeAlertsProps.isMarginHigh) {
    warningList.push("Margin is higher than R$ 8000");
  }
  if (modalEmployeeAlertsProps.isSalaryHigh) {
    warningList.push("Salary is higher than R$ 20000");
  }
  if (modalEmployeeAlertsProps.isMarginHigherThanSalary) {
    warningList.push("Margin is higher than salary");
  }

  return warningList;
};
