import React, { useEffect, useState } from "react";

import { useToaster } from "@creditas/toaster";
import { useTranslation } from "react-i18next";

import {
  EMPLOYEE_CONFIRM_IMPORT_ACTION,
  useEmployeeConfirmImport,
} from "../../services/bff/mutations";
import { useCustomProps } from "../../contexts/CustomPropsContext";
import { Product } from "../../helpers/product";
import { doCardCreationRequest } from "../../api/vr-va/vr-va.api";
import { envVars } from "../../envVars";
import { Sentry } from "../../helpers";
import { StyledPrimaryButton } from "../../shared/styles/primary-button.styles";

interface Props {
  id: string;
  groupId?: string;
  action: EMPLOYEE_CONFIRM_IMPORT_ACTION | undefined;
  createBenefitsCard: boolean;
  isDisabled?: boolean;
  onSuccess?: () => any;
}

const ConfirmEmployeeImportButton = ({
  id,
  groupId,
  createBenefitsCard,
  action,
  isDisabled = true,
  onSuccess = undefined,
}: Props) => {
  const { push } = useToaster();
  const { productType, companySettingsId } = useCustomProps();
  const [employeeConfirmImport, { loading }] = useEmployeeConfirmImport();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { t } = useTranslation();

  const onClick = () => {
    employeeConfirmImport({
      variables: {
        sourceId: id,
        payload: { importAction: action },
        groupId: groupId || companySettingsId?.toString() || "",
      },
    })
      .then(() => {
        push("Importação confirmada com sucesso", {
          level: "success",
          delay: -1,
        });
        onSuccess && onSuccess();

        const payload = {
          url: envVars.VR_VA_API,
          sourceId: `${id}`,
        };
        if (
          (productType === Product.PAYROLL_AND_BENEFITS ||
            productType === Product.BENEFITS) &&
          createBenefitsCard
        ) {
          doCardCreationRequest(payload).catch((error) => {
            const exception = new Error(error);
            const extra = {
              flow: "benefits",
              request: "doCardCreationRequest",
              correlationId: id,
            };
            Sentry.captureExceptionHint(exception, extra);
          });
        }
      })
      .catch((error) => {
        push(`Erro ao confirmar a importação`, {
          level: "danger",
        });
        const exception = new Error(error);
        const extra = {
          flow: "benefits",
          request: "employeeConfirmImport",
          correlationId: id,
        };
        Sentry.captureExceptionHint(exception, extra);
      });
  };

  useEffect(() => {
    setButtonDisabled(isDisabled || loading);
  }, [isDisabled, loading]);

  return (
    <>
      <StyledPrimaryButton
        variant="primary"
        isLoading={loading}
        disabled={buttonDisabled}
        onClick={onClick}
        data-testid="employee-confirm-import"
      >
        {t("hr.importEmployee.step4.confirmButton")}
      </StyledPrimaryButton>
    </>
  );
};

export default ConfirmEmployeeImportButton;
