import { Typography } from "@creditas/typography";
import React from "react";
import { RadioInputField } from "@creditas/input";
import { EMPLOYEE_CONFIRM_IMPORT_ACTION } from "../../../../../services/bff/mutations";
import { ResponseDiffFromLastImport } from "../../../../../services/bff/queries";
import { Spinner } from "@creditas/spinner";

interface Props {
  action: EMPLOYEE_CONFIRM_IMPORT_ACTION | undefined;
  data: ResponseDiffFromLastImport | undefined;
  confirmActionOptions: any;
  handleChangeAction: (selectedAction) => void;
  loading: boolean;
  employeesWithSuccess?: number;
}

export const FeedbackActionComponent: React.FC<Props> = ({
  action,
  data,
  confirmActionOptions,
  handleChangeAction,
  loading,
  employeesWithSuccess
}: Props) => {
  if (loading) {
    return <Spinner message="Loading..." />;
  }
  return (
    <div style={{ marginTop: 60 }}>
      {employeesWithSuccess ? (
        <Typography
          variant="textParagraph"
          style={{ fontSize: 20, lineHeight: 1.5, marginBottom: 10 }}
        >
          Com esta importação {employeesWithSuccess}
          {""} colaboradores serão actualizados. Decida o que você quer fazer
          com os {""} {data?.employeeDiffFromLastImport?.diff}
          {""} colaboradores que já estão na base, mas não fazem parte da lista
          importada:
        </Typography>
      ) : (
        <Typography
          variant="textParagraph"
          style={{ fontSize: 20, lineHeight: 1.5, marginBottom: 10 }}
        >
          Decida o que você quer fazer com os
          {""} {data?.employeeDiffFromLastImport?.diff} {""}
          colaboradores que já estão na base, mas não fazem parte da lista
          importada:
        </Typography>
      )}
      <RadioInputField
        fullWidth
        value={action}
        options={confirmActionOptions}
        onChange={handleChangeAction}
        style={{ maxWidth: "fit-content" }}
      />
    </div>
  );
};
