import { useTranslation } from "react-i18next";
import React, { MouseEvent, useEffect } from "react";

import { TableBody, TableCell, TableHead, TableRow } from "@creditas/table";
import { Action } from "@creditas/icons";
import { Tag } from "@creditas/tag";
import { useToaster } from "@creditas/toaster";

import { useCustomProps } from "../../contexts/CustomPropsContext";
import {
  Import,
  ImportStatus
} from "../../services/bff/queries/useGetEmployeeImports";
import { ImportResultType, useGetFileResult } from "../../services/bff/queries";

import Table from "./EmployeeImportsTable.style";

interface Props {
  imports: Import[];
}

type ImportStatusWithPartial = ImportStatus | "PARTIAL-ERROR" | "TOTAL-ERROR";

export interface ImportWithPartial {
  id: string;
  name: string;
  tenantId: string;
  groupId: string;
  userId: string;
  status: ImportStatus;
  errorLines: number;
  totalLines: number;
  processDate: string;
  isPartial?: boolean;
  statusWithPartial?: ImportStatusWithPartial;
}

const EmployeeImportsTable = ({ imports }: Props) => {
  const { t } = useTranslation();
  const { language } = useCustomProps();
  const [getFileResult, { error: downloadError }] = useGetFileResult();
  const { push } = useToaster();

  useEffect(() => {
    if (downloadError?.message === "403: Forbidden") {
      push(
        "Importação realizada por outra pessoa. Você não pode baixar esse arquivo.",
        {
          level: "warning",
          delay: -1
        }
      );
    }
  }, [downloadError]);

  const onDownloadSuccess = ({
    currentTarget
  }: MouseEvent<HTMLButtonElement>) => {
    const { id } = currentTarget.dataset;
    downloadFile(id!, "SUCCESS");
  };

  const onDownloadError = ({
    currentTarget
  }: MouseEvent<HTMLButtonElement>) => {
    const { id } = currentTarget.dataset;
    downloadFile(id!, "ERROR");
  };

  const downloadFile = (id: string, type: ImportResultType) =>
    getFileResult({
      variables: {
        input: {
          id,
          type
        }
      }
    });

  const addPartialKeys = (importDetails: ImportWithPartial) => {
    const isPartial =
      importDetails.errorLines > 0 &&
      importDetails.errorLines < importDetails.totalLines;
    return {
      ...importDetails,
      isPartial: isPartial,
      statusWithPartial: statusWithPartial(importDetails.status, isPartial)
    };
  };

  const statusWithPartial = (status: ImportStatus, isPartial: boolean) => {
    if (status === "ERROR") {
      const partialStatus = isPartial ? "PARTIAL" : "TOTAL";
      return `${partialStatus}-${status}` as ImportStatusWithPartial;
    } else {
      return status as ImportStatusWithPartial;
    }
  };

  const variantFromStatusWithPartial = (importDetails: ImportWithPartial) =>
    ({
      SUCCESS: "success",
      "TOTAL-ERROR": "danger",
      "PARTIAL-ERROR": "warning",
      PROCESSING: "info",
      WAITING: "danger"
    }[importDetails.statusWithPartial!] || "");

  const canDonwloadSuccess = (statusWithPartial: ImportStatusWithPartial) =>
    ({
      SUCCESS: true,
      "TOTAL-ERROR": false,
      "PARTIAL-ERROR": true,
      PROCESSING: false,
      WAITING: false
    }[statusWithPartial]);

  const canDonwloadError = (statusWithPartial: ImportStatusWithPartial) =>
    ({
      SUCCESS: false,
      "TOTAL-ERROR": true,
      "PARTIAL-ERROR": true,
      PROCESSING: false,
      WAITING: false
    }[statusWithPartial]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell textAlign="left">
            {t("label.latestImports.importFileName")}
          </TableCell>
          <TableCell textAlign="left">{t("label.status")}</TableCell>
          <TableCell textAlign="left">
            {t("label.latestImports.processDate")}
          </TableCell>
          <TableCell textAlign="left"></TableCell>
        </TableRow>
      </TableHead>

      <TableBody variant="striped" stripedColorOdd="white">
        {imports.map((item: ImportWithPartial) => {
          const itemWithPartialKeys = addPartialKeys(item);
          return (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>
                <Tag
                  label={t(
                    `enum.employeeImportStatus.status.${itemWithPartialKeys.statusWithPartial}`
                  )}
                  variant={variantFromStatusWithPartial(itemWithPartialKeys)}
                />
              </TableCell>
              <TableCell>
                {item.processDate
                  ? new Date(item.processDate).toLocaleDateString(language)
                  : "-"}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {canDonwloadSuccess(itemWithPartialKeys.statusWithPartial) && (
                  <button
                    className="errors"
                    title="Download file with succeeded list of employees"
                    data-id={itemWithPartialKeys.id}
                    data-testid={`item-${itemWithPartialKeys.id}`}
                    onClick={onDownloadSuccess}
                  >
                    <Action.Download color="success" />
                  </button>
                )}

                {canDonwloadError(itemWithPartialKeys.statusWithPartial) && (
                  <button
                    className="errors"
                    title="Download file with failed list of employees"
                    data-id={itemWithPartialKeys.id}
                    data-testid={`item-${itemWithPartialKeys.id}`}
                    onClick={onDownloadError}
                  >
                    <Action.Download color="danger" />
                  </button>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default EmployeeImportsTable;
