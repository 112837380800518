export const step2Analytics = {
  event: "gaCreditasEvent",
  category: "EmployeeImport",
  action: "GoToStep1Importation"
};

export const step3Analytics = {
  event: "gaCreditasEvent",
  category: "EmployeeImport",
  action: "GoToStep2Importation"
};

export const step5Analytics = {
  processingImport: {
    event: "gaCreditasEvent",
    category: "EmployeeImport",
    action: "LoadEmployeeImportStep5"
  },
  importHasPendencies: {
    event: "gaCreditasEvent",
    category: "EmployeeImport",
    action: "LoadImportHasPendencies"
  },
  importHasNoPendencies: {
    event: "gaCreditasEvent",
    category: "EmployeeImport",
    action: "LoadImportHasNoPendencies"
  },
  goToEmployeesWhileStillProcessingImport: {
    event: "gaCreditasEvent",
    category: "EmployeeImport",
    action: "GoToEmployeesWhileStillProcessingImport"
  }
};
