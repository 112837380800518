import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useToaster } from "@creditas/toaster";
import { getGroupSalaryType } from "../../../api/hr-portal-bff/hr-portal-bff.api";
import { envVars } from "../../../envVars";
import { checkIfFileHasRowsBesidesHeader } from "../../../shared/helpers/helpers";
import { emitEvent, Sentry } from "../../../helpers";
import { Product } from "../../../helpers/product";
import XlsxWorker from "../../../helpers/XlsxWorker";
import { featureFlag } from "../../../helpers/feature-flag/feature-flag";
import { useCustomProps } from "../../../contexts/CustomPropsContext";
import { useUploadFile } from "../../../services/bff/mutations";
import { HR_SYSTEM_VALUES } from "../ImportHrEmployeeSystemValues";
import { SalaryType, Step } from "../ImportHrEmployeeTypes";
import { BENEFITS_EMPLOYEE_SYSTEM_VALUES } from "../BenefitsEmployeeSystemValues";
import { ONLY_PAYROLL_SYSTEM_VALUES } from "../OnlyPayrollEmployeeSystemValues";
import { ONLY_PAYROLL_SYSTEM_VALUES_BASE_SALARY } from "../OnlyPayrollEmployeeSystemValuesSalaryBase";
import { HR_SYSTEM_VALUES_BASE_SALARY } from "../ImportHrEmployeeSystemValuesSalaryBase";
import {
  EMPLOYEE_IMPORTER_SPREADSHEET_CONSIGNADO_BASE_SALARY,
  EMPLOYEE_IMPORTER_SPREADSHEET_CONSIGNADO,
  EMPLOYEE_IMPORTER_SPREADSHEET_BASE_SALARY,
  EMPLOYEE_IMPORTER_SPREADSHEET,
  EMPLOYEE_IMPORTER_SPREADSHEET_ONLY_BENEFITS,
  EMPLOYEE_IMPORTER_SPREADSHEET_CONSIGNADO_NET_SALARY,
  EMPLOYEE_IMPORTER_SPREADSHEET_NET_SALARY
} from "../../../helpers/spreadsheetsUrls";
import { HR_SYSTEM_VALUES_NET_SALARY } from "../ImportHrEmployeeSystemValuesNetSalary";
import { ONLY_PAYROLL_SYSTEM_VALUES_NET_SALARY } from "../OnlyPayrollEmployeeSystemValuesNetSalary";
import { UserType } from "../../../interfaces/userType";

const getSystemValuesByProduct = (
  productType: Product,
  salaryType?: SalaryType
) => {
  switch (productType) {
    case Product.PAYROLL:
      if (salaryType === SalaryType.BASE_SALARY)
        return ONLY_PAYROLL_SYSTEM_VALUES_BASE_SALARY;
      else if (salaryType === SalaryType.NET_SALARY)
        return ONLY_PAYROLL_SYSTEM_VALUES_NET_SALARY;
      else if (salaryType === SalaryType.PRE_ESTABLISHED_MARGIN)
        return ONLY_PAYROLL_SYSTEM_VALUES;
      else return ONLY_PAYROLL_SYSTEM_VALUES;
    case Product.PAYROLL_AND_BENEFITS:
      if (salaryType === SalaryType.BASE_SALARY)
        return HR_SYSTEM_VALUES_BASE_SALARY;
      else if (salaryType === SalaryType.NET_SALARY)
        return HR_SYSTEM_VALUES_NET_SALARY;
      else if (salaryType === SalaryType.PRE_ESTABLISHED_MARGIN)
        return HR_SYSTEM_VALUES;
      else return HR_SYSTEM_VALUES;
    case Product.BENEFITS:
      return BENEFITS_EMPLOYEE_SYSTEM_VALUES;
    default:
      return [];
  }
};

const getSpreadsheetTemplateUrlByProduct = (
  productType: Product,
  salaryType?: SalaryType
) => {
  switch (productType) {
    case Product.PAYROLL:
      if (salaryType == SalaryType.BASE_SALARY)
        return EMPLOYEE_IMPORTER_SPREADSHEET_CONSIGNADO_BASE_SALARY;
      else if (salaryType == SalaryType.NET_SALARY)
        return EMPLOYEE_IMPORTER_SPREADSHEET_CONSIGNADO_NET_SALARY;
      else return EMPLOYEE_IMPORTER_SPREADSHEET_CONSIGNADO;
    case Product.PAYROLL_AND_BENEFITS:
      if (salaryType === SalaryType.BASE_SALARY)
        return EMPLOYEE_IMPORTER_SPREADSHEET_BASE_SALARY;
      else if (salaryType === SalaryType.NET_SALARY)
        return EMPLOYEE_IMPORTER_SPREADSHEET_NET_SALARY;
      else return EMPLOYEE_IMPORTER_SPREADSHEET;
    case Product.BENEFITS:
      return EMPLOYEE_IMPORTER_SPREADSHEET_ONLY_BENEFITS;
    default:
      return "";
  }
};

interface Step1ContainerHook {
  handleSend(): Promise<void>;
  handleDownload(): Promise<void>;
  setFile(file: File): void;
  setCompanySettingsIdSelectedByMaster(companySettingId: string): void;
  companySettingsIdSelectedByMaster: string | undefined;
  companySettingsIdOfImportation: number | undefined;
}

export const useStep1Container = (
  setStep: Dispatch<SetStateAction<Step>>
): Step1ContainerHook => {
  const { companySettingsId, productType, userType } = useCustomProps();
  const { t } = useTranslation();
  const { push } = useToaster();
  const [uploadFile] = useUploadFile();
  const [file, setFile] = useState<File>();
  const [
    companySettingsIdSelectedByMaster,
    setCompanySettingsIdSelectedByMaster
  ] = useState<string | undefined>();
  const [
    companySettingsIdOfImportation,
    setcompanySettingsIdOfImportation
  ] = useState<number>();
  const [salaryTypeOfImportation, setSalaryTypeOfImportation] = useState<
    SalaryType
  >();

  const isProductPayrollOrPayrollAndBenefits =
    productType === Product.PAYROLL ||
    productType === Product.PAYROLL_AND_BENEFITS;

  const setFilePreviewAndSystemValues = useCallback(
    (fileAsJson: string[][], uploadKey: string, salaryType?: SalaryType) => {
      setStep(previousState => ({
        ...previousState,
        uploadFileAsJson: fileAsJson,
        uploadPreview: fileAsJson.slice(0, 10),
        uploadSystemValues: getSystemValuesByProduct(productType, salaryType),
        uploadKey: uploadKey,
        companySettingsIdSelectedByMaster: companySettingsIdSelectedByMaster
      }));
    },
    [setStep, productType, companySettingsIdSelectedByMaster]
  );

  useEffect(() => {
    const selectCompanySettingsDependingUserType = () => {
      let groupId: number | undefined;
      if (userType === UserType.MASTER) {
        groupId = Number(companySettingsIdSelectedByMaster);
      } else if (userType === UserType.MANAGER) {
        groupId = companySettingsId;
      }
      if (groupId != undefined) {
        setcompanySettingsIdOfImportation(groupId);
        featureFlag.initClientWithCompany(groupId.toString());
      }
    };
    selectCompanySettingsDependingUserType();
  }, [userType, companySettingsId, companySettingsIdSelectedByMaster]);

  useEffect(() => {
    if (
      companySettingsIdOfImportation &&
      isProductPayrollOrPayrollAndBenefits
    ) {
      const getSalaryType = async () => {
        const salaryType = await getGroupSalaryType({
          url: envVars.HR_PORTAL_BFF_URL!,
          groupId: companySettingsIdOfImportation.toString()
        });
        setStep(previousState => ({
          ...previousState,
          salaryType
        }));
        setSalaryTypeOfImportation(salaryType);
      };
      getSalaryType();
    }
  }, [
    companySettingsIdOfImportation,
    isProductPayrollOrPayrollAndBenefits,
    setStep
  ]);

  const handleSend = useCallback(async () => {
    return Promise.all([
      new XlsxWorker().xlsxToArray(file!!),
      uploadFile({
        variables: {
          file: file!!,
          groupId: companySettingsIdOfImportation
        }
      })
    ])
      .then(async ([xlsxWorkerResponse, { data }]) => {
        checkIfFileHasRowsBesidesHeader(xlsxWorkerResponse.preview);
        setFilePreviewAndSystemValues(
          xlsxWorkerResponse.preview,
          data!.uploadImporterFile,
          salaryTypeOfImportation
        );
        if (xlsxWorkerResponse.showWarning) {
          push(t("hr.globalErrorMessage.warningMultipleWorksheets"), {
            level: "warning",
            delay: -1
          });
        }
        emitEvent({
          event: "gaCreditasEvent",
          category: "EmployeeImport",
          action: "UploadEmployeeTemplate",
          label: { filename: file!!.name }
        });
      })
      .catch(error => {
        let userErrorMessage = t("hr.globalErrorMessage.uploadFile");
        let internalErrorMessage = `${userErrorMessage} {${error}}`;
        emitEvent({
          event: "gaCreditasEvent",
          category: "EmployeeImport",
          action: "UploadEmployeeTemplate",
          label: { filename: file!!.name, details: internalErrorMessage }
        });
        const exception = new Error(userErrorMessage);
        const extra = {
          flow: "benefits",
          request: "uploadFile",
          groupId: companySettingsIdOfImportation
        };
        Sentry.captureExceptionHint(exception, extra);
        throw exception;
      });
  }, [
    companySettingsIdOfImportation,
    file,
    push,
    salaryTypeOfImportation,
    setFilePreviewAndSystemValues,
    t,
    uploadFile
  ]);

  const handleDownload = useCallback(async () => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "DownloadEmployeeTemplate"
    });
    const spreadsheetUrl = getSpreadsheetTemplateUrlByProduct(
      productType,
      salaryTypeOfImportation
    );
    window.open(spreadsheetUrl, "_blank", "noopener");
  }, [productType, salaryTypeOfImportation]);

  return {
    handleSend,
    handleDownload,
    setFile,
    setCompanySettingsIdSelectedByMaster,
    companySettingsIdSelectedByMaster,
    companySettingsIdOfImportation
  };
};
