import { Table } from "@creditas/table";
import { styled } from "@creditas/stylitas";

import { getColor } from "../../helpers/designSystem";

export default styled(Table)`
  width: 100%;
  margin-bottom: 20px;

  thead {
    border-bottom: 0;
  }
  th {
    font-weight: bold;
    padding-bottom: 25px;
    color: ${getColor("neutral06")};
  }
  td:nth-of-type(6n) {
    max-width: 400px;
  }
  div.status-tag {
    cursor: default;
    width: 245px;
  }
  button.errors {
    border: 0;
    outline: 0;
    cursor: pointer;
    background: transparent;
  }
`;
