export interface UploadSpreedsheetTextProps {
  title: string;
  subtitle: string;
  textMain: string;
  textStep1: string;
  textStep2: string;
  textStep3: string;
  information?: string;
}

interface SalaryTypeInformationProps {
  netSalaryStep1Text: string;
  baseSalaryStep1Text: string;
  preEstablishedMarginSalaryStep1Text: string;
}

export interface UploadSpreedsheetTextVersionsProps {
  onlyPayroll: UploadSpreedsheetTextProps;
  payrollAndBenefits: UploadSpreedsheetTextProps;
  onlyBenefits: UploadSpreedsheetTextProps;
  salaryTypeInformation: SalaryTypeInformationProps;
  error: UploadSpreedsheetTextProps;
}

export const UPLOAD_SPREADSHEET_TEXTS: UploadSpreedsheetTextVersionsProps = {
  onlyPayroll: {
    title: "hr.importEmployee.step1.uploadSpreadSheet.payrollFlow.title",
    subtitle: "hr.importEmployee.step1.uploadSpreadSheet.payrollFlow.subtitle",
    textMain: "hr.importEmployee.step1.uploadSpreadSheet.payrollFlow.text.main",
    textStep1:
      "hr.importEmployee.step1.uploadSpreadSheet.payrollFlow.text.step1",
    textStep2:
      "hr.importEmployee.step1.uploadSpreadSheet.payrollFlow.text.step2",
    textStep3:
      "hr.importEmployee.step1.uploadSpreadSheet.payrollFlow.text.step3",
    information: undefined
  },
  payrollAndBenefits: {
    title: "hr.importEmployee.step1.uploadSpreadSheet.hrPortalFlow.title",
    subtitle: "hr.importEmployee.step1.uploadSpreadSheet.hrPortalFlow.subtitle",
    textMain:
      "hr.importEmployee.step1.uploadSpreadSheet.hrPortalFlow.text.main",
    textStep1:
      "hr.importEmployee.step1.uploadSpreadSheet.hrPortalFlow.text.step1",
    textStep2:
      "hr.importEmployee.step1.uploadSpreadSheet.hrPortalFlow.text.step2",
    textStep3:
      "hr.importEmployee.step1.uploadSpreadSheet.hrPortalFlow.text.step3",
    information:
      "hr.importEmployee.step1.uploadSpreadSheet.hrPortalFlow.informationText"
  },
  onlyBenefits: {
    title: "hr.importEmployee.step1.uploadSpreadSheet.onlyBenefitsFlow.title",
    subtitle:
      "hr.importEmployee.step1.uploadSpreadSheet.onlyBenefitsFlow.subtitle",
    textMain:
      "hr.importEmployee.step1.uploadSpreadSheet.onlyBenefitsFlow.text.main",
    textStep1:
      "hr.importEmployee.step1.uploadSpreadSheet.onlyBenefitsFlow.text.step1",
    textStep2:
      "hr.importEmployee.step1.uploadSpreadSheet.onlyBenefitsFlow.text.step2",
    textStep3:
      "hr.importEmployee.step1.uploadSpreadSheet.onlyBenefitsFlow.text.step3",
    information:
      "hr.importEmployee.step1.uploadSpreadSheet.onlyBenefitsFlow.informationText"
  },
  salaryTypeInformation: {
    netSalaryStep1Text:
      "hr.importEmployee.step1.uploadSpreadSheet.netSalaryInformation",
    baseSalaryStep1Text:
      "hr.importEmployee.step1.uploadSpreadSheet.baseSalaryInformation",
    preEstablishedMarginSalaryStep1Text:
      "hr.importEmployee.step1.uploadSpreadSheet.preEstablishedMarginInformation"
  },
  error: {
    title: "ERROR: product type not found",
    subtitle: "",
    textMain: "",
    textStep1: "",
    textStep2: "",
    textStep3: "",
    information: undefined
  }
};
