import React, { FC, useState, useCallback, useEffect } from "react";

import { File } from "@creditas/icons";
import Container from "./FilesDropZone.style";

interface Props {
  accept?: string;
  multiple?: boolean;
  onChangeFiles?: (files: FileList) => void;
}

const FilesDropZone: FC<Props> = ({ accept, onChangeFiles, multiple }) => {
  const [files, setFiles] = useState<FileList | null>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const acceptedFileTypes = ["xls", "xlsx"];

  const handleInputChange = useCallback(({ target }) => {
    if (target.files) {
      setFiles(target.files);
    }
  }, []);
  const handleDragOver = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  }, []);
  const handleDragLeave = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  }, []);
  const handleDrop = useCallback(event => {
    handleDragLeave(event);
    if (event.dataTransfer.files.length) {
      const fileName = event.dataTransfer.files[0].name;
      const fileExtension = fileName.split(".").pop();
      if (acceptedFileTypes.includes(fileExtension)) {
        setFiles(event.dataTransfer.files);
      } else {
        window.alert(
          "Arquivo errado. Por favor, insira um arquivo .xls ou .xlsx"
        );
      }
    }
  }, []);
  const setFocusOn = useCallback(() => {
    setHasFocus(true);
  }, []);
  const setFocusOff = useCallback(() => {
    setHasFocus(false);
  }, []);

  useEffect(() => {
    if (onChangeFiles && files) {
      onChangeFiles(files);
    }
  }, [files]);

  const Icon = files?.item(0) ? File.FileText : File.Upload;

  return (
    <Container
      hasFocus={hasFocus}
      dragActive={dragActive}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragOver}
      onDragEnd={handleDragLeave}
      onDragLeave={handleDragLeave}
      onMouseDown={setFocusOn}
      onMouseUp={setFocusOff}
      onMouseLeave={setFocusOff}
    >
      <input
        hidden
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleInputChange}
        data-testid="files-drop-zone"
      />
      <Icon fontSize="large" />
    </Container>
  );
};

export default FilesDropZone;
