import React from "react";
import {
  Backdrop,
  Box,
  ButtonPrimaryTextOnly,
  ButtonSecondary,
  IconDeleteDisabled,
  IconWarning_2,
  Tag,
  Typography,
  useTheme
} from "@creditas-ui/react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  THeadCell
} from "payroll-ui";
import { useTranslation } from "react-i18next";
import { ScrollArea } from "./table-scroll-area.styles";
import { EmployeeAlertData } from "./ModalAlertsTableTypes";

interface Props {
  open: boolean;
  employees: EmployeeAlertData[];
  isMarginHigh?: boolean;
  isSalaryHigh?: boolean;
  isMarginHigherThanSalary?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ModalAlertsTableComponent: React.FC<Props> = ({
  open,
  employees,
  isMarginHigh = false,
  isSalaryHigh = false,
  isMarginHigherThanSalary = false,
  onConfirm,
  onCancel
}: Props) => {
  const { t } = useTranslation();
  const tPath = "hr.importEmployee.step2.modalAlertsTable";
  const { colors } = useTheme();

  const isNameColumn = employees[0].name !== undefined;
  const isCPFColumn = employees[0].cpf !== undefined;
  const isCNPJColumn = employees[0].cnpj !== undefined;
  const isMarginColumn = employees[0].margin !== undefined;
  const isSalaryColumn = employees[0].salary !== undefined;

  return (
    <Backdrop open={open} zIndex={10000}>
      <Box padding={20} width={"100%"} height={"100%"}>
        <Box
          width={"100%"}
          height={"100%"}
          backgroundColor={colors.neutral[0]}
          borderRadius={16}
        >
          <ScrollArea height="100%">
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
            >
              <Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  paddingLeft={24}
                  paddingRight={24}
                  paddingBottom={20}
                  paddingTop={20}
                  width={"100%"}
                >
                  <Typography variant="bodyMdMedium">
                    {t(tPath + ".modalTitle")}
                  </Typography>
                  <ButtonSecondary
                    role={"button"}
                    aria-label="close"
                    onClick={onCancel}
                  >
                    <IconDeleteDisabled size={24} onClick={onCancel} />
                  </ButtonSecondary>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  paddingLeft={24}
                  paddingBottom={20}
                  paddingTop={20}
                  backgroundColor={"#E3790014"}
                  width={"100%"}
                >
                  <Box paddingRight={22}>
                    <IconWarning_2 color="#E37900" size={20} />
                  </Box>
                  <Typography variant="bodyMdRegular">
                    {t(tPath + ".alert")}
                  </Typography>
                </Box>
                <Box
                  paddingLeft={24}
                  paddingRight={24}
                  paddingBottom={20}
                  paddingTop={40}
                  width={"100%"}
                >
                  <Typography variant="bodyXlMedium" paddingBottom={40}>
                    {t(tPath + ".title")}
                  </Typography>
                  <Box paddingBottom={40} display={"flex"}>
                    {isMarginHigh && (
                      <Box paddingRight={12}>
                        <Tag
                          size="small"
                          style={{ backgroundColor: "#E3790014" }}
                        >
                          {t(tPath + ".tags.highMargin")}
                        </Tag>
                      </Box>
                    )}
                    {isSalaryHigh && (
                      <Box paddingRight={12}>
                        <Tag
                          size="small"
                          style={{ backgroundColor: "#E3790014" }}
                        >
                          {t(tPath + ".tags.highSalary")}
                        </Tag>
                      </Box>
                    )}
                    {isMarginHigherThanSalary && (
                      <Box paddingRight={12}>
                        <Tag
                          size="small"
                          style={{ backgroundColor: "#DC383814" }}
                        >
                          {t(tPath + ".tags.marginHigherThanSalary")}
                        </Tag>
                      </Box>
                    )}
                  </Box>
                  <Box paddingBottom={40}>
                    <ScrollArea maxHeight="35vh" stickyHeader>
                      <Table fullWidth={true}>
                        <TableHead>
                          <TableRow style={{ borderTop: "none" }}>
                            {isNameColumn && (
                              <THeadCell>{t(tPath + ".table.name")}</THeadCell>
                            )}
                            {isCPFColumn && (
                              <THeadCell>{t(tPath + ".table.cpf")}</THeadCell>
                            )}
                            {isCNPJColumn && (
                              <THeadCell>{t(tPath + ".table.cnpj")}</THeadCell>
                            )}
                            {isSalaryColumn && (
                              <THeadCell>
                                {t(tPath + ".table.salary")}
                              </THeadCell>
                            )}
                            {isMarginColumn && (
                              <THeadCell>
                                {t(tPath + ".table.margin")}
                              </THeadCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {employees &&
                            employees.map(employee => (
                              <TableRow key={employee.cpf}>
                                {isNameColumn && (
                                  <TableCell>{employee.name}</TableCell>
                                )}
                                {isCPFColumn && (
                                  <TableCell>{employee.cpf}</TableCell>
                                )}
                                {isCNPJColumn && (
                                  <TableCell>{employee.cnpj}</TableCell>
                                )}
                                {isSalaryColumn && (
                                  <TableCell>{employee.salary}</TableCell>
                                )}
                                {isMarginColumn && (
                                  <TableCell>{employee.margin}</TableCell>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </ScrollArea>
                  </Box>
                  <Box paddingBottom={40}>
                    <Typography variant="bodyMdRegular">
                      {t(tPath + ".explanation1")}
                    </Typography>
                    <Typography variant="bodyMdRegular">
                      {t(tPath + ".explanation2")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                style={{ gap: 40 }}
                paddingRight={24}
                paddingBottom={32}
              >
                <ButtonSecondary onClick={onCancel}>
                  {t(tPath + ".cancel")}
                </ButtonSecondary>
                <ButtonPrimaryTextOnly onClick={onConfirm}>
                  {t(tPath + ".confirm")}
                </ButtonPrimaryTextOnly>
              </Box>
            </Box>
          </ScrollArea>
        </Box>
      </Box>
    </Backdrop>
  );
};
