import { gql, useMutation } from "@creditas/apollo";
import { EmployeeImportDiffWithDocument } from "../queries";

export interface DeleteEmployeesPayloadParams {
  employees: EmployeeImportDiffWithDocument[];
  sourceId: string | undefined;
}

export interface DeleteEmployeesPayload {
  payload: DeleteEmployeesPayloadParams;
}

const EMPLOYEE_DELETE = gql`
  mutation employeeDelete($payload: DeleteEmployeesPayload!) {
    employeeDelete(payload: $payload) {
      employees {
        name
        mainDocument {
          code
          type
        }
      }
    }
  }
`;

export interface MainDocument {
  code: string;
  type: string; // Change it to enum
}

export interface Response {
  employeeDelete: {
    employees: [
      {
        name: string;
        mainDocument: MainDocument;
      }
    ];
  };
}

const useEmployeesDelete = () =>
  useMutation<Response, DeleteEmployeesPayload>(EMPLOYEE_DELETE);

export { useEmployeesDelete };
