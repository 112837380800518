import { gql, useMutation } from "@creditas/apollo";

export interface EmployeeImportColumnMatch {
  nome?: number;
  cpf?: number;
  cnpj?: number;
  margem?: number;
  salario?: number;
  status?: number;
  admissao?: number;
  matricula?: number;
  has_external_loan?: number;
  email?: number;
  phone?: number;
  employee_type?: number;
}

// Making everything optional while this is not fixed
export interface MapResponse {
  id?: number;
  insertCount?: number;
  updateCount?: number;
  deleteCount?: number;
  pendingCount?: number;
  statusesInFile: string[] | null;
}

export const SET_COLUMN_MAP = gql`
  mutation employeeImportColumnMatch(
    $id: Int
    $payload: EmployeeImportColumnMatch
  ) {
    employeeImportColumnMatch(id: $id, payload: $payload) {
      id
      insertCount
      updateCount
      deleteCount
      pendingCount
      statusesInFile
    }
  }
`;

const useDexterSetColumnMap = (
  id: number,
  payload: EmployeeImportColumnMatch
) => {
  return useMutation(SET_COLUMN_MAP, {
    variables: { id, payload }
  });
};

export { useDexterSetColumnMap };
