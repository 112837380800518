import * as React from "react";
import Container from "./Quote.style";

interface Props {
  text: string;
}
const Quote = ({ text }: Props) => {
  return <Container>{text}</Container>;
};

export default Quote;
