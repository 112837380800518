export const envVars = {
  appName: "employee-import",
  sentryDsn: process.env.SENTRY_DSN,
  release: process.env.COMMIT_RELEASE,
  environment: process.env.name,
  BFF_URI: process.env.BFF_URI,
  BUZZ_URL: process.env.BUZZ_URL,
  HR_PORTAL_BFF_URL: process.env.HR_PORTAL_BFF_URL,
  VR_VA_API: process.env.VR_VA_API,
  ADMIN_URL: process.env.ADMIN_URL,
  SPLIT_API_KEY: process.env.SPLIT_API_KEY
};
