import { InputSelect } from "@creditas/form-elements";
import { useToaster } from "@creditas/toaster";
import { Typography } from "@creditas/typography";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledPrimaryButton } from "../../shared/styles/primary-button.styles";
import { StyledSecondaryButton } from "../../shared/styles/secondary-button.styles";
import { useCustomProps } from "../../contexts/CustomPropsContext";
import { emitEvent } from "../../helpers";
import { useGetCompaniesSettings } from "../../services/bff/queries/useGetCompaniesSettings";
import FilesDropZone from "../FilesDropZone/FilesDropZone";
import Container, { HrCheckbox } from "./UploadFile.style";
import { UserType } from "../../interfaces/userType";

export interface Props {
  onSend(): Promise<void>;
  onDownload(): void;
  setFile(file: File): void;
  setCompanySettingsIdSelectedByMaster(companySettingId: string): void;
  companySettingsIdSelectedByMaster: string | undefined;
  companySettingsIdOfImportation: number | undefined;
  isDownloading?: boolean;
}

const UploadFile = ({
  onSend,
  onDownload,
  setFile,
  setCompanySettingsIdSelectedByMaster,
  companySettingsIdSelectedByMaster,
  companySettingsIdOfImportation,
  isDownloading
}: Props) => {
  const { userType } = useCustomProps();
  const { t } = useTranslation();
  const { push } = useToaster();
  const [isSendFileLoading, setIsSendFileLoading] = useState(false);
  const [isFileDroped, setIsFileDropped] = useState(false);
  const [isResponsibilityCheck, setResponsibilityCheck] = useState(false);
  const [fileName, setFileName] = useState<string>();

  const handleFileChange = useCallback(
    (files: FileList) => {
      if (files[0]) {
        setFile(files[0]);
        emitEvent({
          event: "gaCreditasPayrollAdmin",
          category: "employeeImport",
          action: "selectFile",
          label: { filename: files[0].name }
        });
        setIsFileDropped(true);
        setFileName(files[0].name);
      }
    },
    [setFile]
  );

  const acceptedFiles = ".xls, .xlsx";

  const [
    getCompaniesSettings,
    { data: companiesSettings, called: companiesSettingsCalled }
  ] = useGetCompaniesSettings();

  const companiesSettingsAsOptions = () =>
    companiesSettings?.companySettings.map(companySettings => ({
      value: companySettings.id.toString(),
      text: companySettings.groupName
    })) || [];

  const onSelectChanged = (event: ChangeEvent<HTMLSelectElement>) =>
    setCompanySettingsIdSelectedByMaster(event.currentTarget.value);

  useEffect(() => {
    if (userType === UserType.MASTER) {
      getCompaniesSettings();
    }
  }, [getCompaniesSettings, userType]);

  const sendFile = useCallback(() => {
    if (isSendFileLoading) {
      return;
    }
    setIsSendFileLoading(true);
    onSend().catch(error => {
      setIsSendFileLoading(false);
      push(error.message || "Um erro desconhecido aconteceu...", {
        level: "danger",
        delay: -1
      });
    });
    setIsSendFileLoading(false);
  }, [onSend, push, isSendFileLoading]);

  const onDownloadCallback = useCallback(() => {
    onDownload();
  }, [onDownload]);

  return (
    <Container>
      <div className="w100p">
        <FilesDropZone
          accept={acceptedFiles}
          onChangeFiles={handleFileChange}
        />
        <Typography variant="support">{fileName}</Typography>
      </div>
      <div className="w100p">
        <Typography variant="h6">{t("label.dropFilesHere")}</Typography>
        <Typography variant="support">
          {t("label.acceptedFormats")} {acceptedFiles}
        </Typography>
      </div>

      <HrCheckbox
        checked={isResponsibilityCheck}
        onChange={() => setResponsibilityCheck(!isResponsibilityCheck)}
        style={{
          textAlign: "left"
        }}
      >
        {t("label.checkbox")}
      </HrCheckbox>

      <div className="w100p">
        {companiesSettingsCalled && (
          <InputSelect
            onChange={onSelectChanged}
            options={companiesSettingsAsOptions()}
            label="Importa para empresa"
            value={companySettingsIdSelectedByMaster}
            size="comfortable"
            data-testid="companies-settings-select"
          />
        )}

        <StyledPrimaryButton
          fullWidth
          disabled={
            !isFileDroped ||
            !companySettingsIdOfImportation ||
            !isResponsibilityCheck
          }
          onClick={sendFile}
          isLoading={isSendFileLoading}
          data-testid="submit-file"
        >
          {t("label.send")}
        </StyledPrimaryButton>
        <StyledSecondaryButton
          fullWidth
          disabled={!companySettingsIdOfImportation}
          variant="secondary"
          isLoading={isDownloading}
          onClick={onDownloadCallback}
        >
          {t("label.downloadTemplate")}
        </StyledSecondaryButton>
      </div>
    </Container>
  );
};

export default UploadFile;
