import { gql, useLazyQuery } from "@creditas/apollo";

export const FILE_IMPORTER_STATUS = gql`
  query getFileImportStatus($id: ID!) {
    getFileImportStatus(id: $id) {
      status
    }
  }
`;

export interface Variables {
  id: string;
}

export type ImportStatus = "WAITING" | "IN_PROGRESS" | "ERROR" | "SUCCESS";

export interface StatusResponse {
  getFileImportStatus: {
    status: ImportStatus;
  };
}

export const useGetFileImportStatus = () => {
  return useLazyQuery<StatusResponse, Variables>(FILE_IMPORTER_STATUS, {
    pollInterval: 4000
  });
};
