import { gql, useQuery } from "@creditas/apollo";
import { SpringPagination } from "interfaces";

export const GET_EMPLOYEE_IMPORTS = gql`
  query employeeImportGetImports(
    $pagination: Pagination
    $groupId: String
    $importType: ImportType
  ) {
    employeeImportGetImports(
      pagination: $pagination
      groupId: $groupId
      importType: $importType
    ) {
      content {
        id
        name
        tenantId
        groupId
        userId
        status
        errorLines
        totalLines
        processDate
      }
      totalElements
    }
  }
`;

export const useGetEmployeeImports = (
  pagination: Pagination,
  groupId: string = "",
  importType: ImportType = ImportType.EMPLOYEE
) => {
  return useQuery<Response, Variables>(GET_EMPLOYEE_IMPORTS, {
    variables: {
      pagination,
      groupId,
      importType
    }
  });
};
interface Pagination {
  page: number;
  size: number;
}

export enum ImportType {
  EMPLOYEE = "EMPLOYEE",
  HR_EMPLOYEE = "HR_EMPLOYEE",
  BENEFITS_EMPLOYEE = "BENEFITS_EMPLOYEE"
}

interface Response {
  employeeImportGetImports: SpringPagination<Import>;
}

interface Variables {
  pagination: Pagination;
  groupId: string;
  importType?: ImportType;
}

export type ImportStatus = "WAITING" | "IN_PROGRESS" | "ERROR" | "SUCCESS";

export interface Import {
  id: string;
  name: string;
  tenantId: string;
  groupId: string;
  userId: string;
  status: ImportStatus;
  errorLines: number;
  totalLines: number;
  processDate: string;
}
