import { gql, useLazyQuery } from "@creditas/apollo";

const FILE_IMPORTER_FILE_LINE_ERRORS = gql`
  query employeeImportGetFileLineErrors($id: ID!, $columnType: String!) {
    employeeImportGetFileLineErrors(id: $id, columnType: $columnType) {
      emptyIdentifierCount
      errors
    }
  }
`;

interface Variables {
  id: string;
  columnType: string;
}

interface LineErrors {
  [key: string]: string[];
}

export interface SummaryResponse {
  employeeImportGetFileLineErrors: {
    emptyIdentifierCount: number;
    errors: LineErrors;
  };
}

export const useGetFileLineErrors = () => {
  return useLazyQuery<SummaryResponse, Variables>(
    FILE_IMPORTER_FILE_LINE_ERRORS
  );
};
