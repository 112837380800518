import { useTranslation } from "react-i18next";

export function usePaginationTranslation() {
  const { t } = useTranslation();
  return {
    overview(currentPage, totalPages) {
      return t("label.pagination.overview", { totalPages, currentPage });
    },
    select(pageSize) {
      return t("label.pagination.select", { pageSize });
    }
  };
}
