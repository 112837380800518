import { IndexColumnsMap } from "../pages/ImportHrEmployee/step2/Step2Types";
import { ColumnMap } from "../services/bff/mutations";

export const indexColumnsMapToColumnsMap = (
  indexColumnsMap: IndexColumnsMap,
  preview: string[][]
): ColumnMap =>
  Object.keys(indexColumnsMap).reduce((accumulator, key) => {
    return {
      ...accumulator,
      [key]: preview[0][indexColumnsMap[key]]
    };
  }, {});
