import { Typography } from "@creditas/typography";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomTag from "../../../../../components/CustomTag/CustomTag";

interface Props {
  scheduled: number;
  success: number;
  error: number;
}

export const FeedbackSummaryComponent: React.FC<Props> = ({
  scheduled,
  success,
  error,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h4" data-testid="confirm-title">
        {t("hr.importEmployee.step4.feedbackDetails.processing.title")}
      </Typography>
      <Typography variant="h6">
        {t("hr.importEmployee.step4.feedbackDetails.processing.subtitle")}
      </Typography>
      <ul>
        <li>
          <CustomTag label={String(scheduled)} />
          <Typography>
            {t(
              "hr.importEmployee.step4.feedbackDetails.processing.scheduledText"
            )}
          </Typography>
        </li>
        <li>
          <CustomTag label={String(success)} />
          <Typography>
            {t("hr.importEmployee.step4.feedbackDetails.processing.sucessText")}
          </Typography>
        </li>
        <li>
          <CustomTag label={String(error)} />
          <Typography>
            {t("hr.importEmployee.step4.feedbackDetails.processing.errorText")}
          </Typography>
        </li>
      </ul>
    </>
  );
};
