import React from "react";
import i18n from "i18next";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { initReactI18next } from "react-i18next";

import { App } from "./App";
import { AppCustomProps } from ".";
import { envVars } from "./envVars";
import { Sentry, createTracer } from "./helpers";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  domElementGetter() {
    return document.querySelector(`[data-app="${envVars.appName}"]`)!;
  }
});

const bootstrap = [
  reactLifecycles.bootstrap,
  async ({ language }: AppCustomProps) => {
    const translations = require(`./translations/${language}.json`);
    const resources = {
      [language]: {
        common: translations
      }
    };
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources,
        ns: ["common"],
        defaultNS: "common",
        lng: language,
        interpolation: {
          escapeValue: false // react already safes from xss
        }
      });
    Sentry.init();
    return Promise.resolve();
  },
  //// Example tracer of new relic
  _ => createTracer(envVars.appName, "bootstrap")
];

const mount = [reactLifecycles.mount];

const unmount = [reactLifecycles.unmount];

export { bootstrap, mount, unmount };
