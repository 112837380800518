import { QueryLazyOptions } from "@apollo/client";
import { LazyQueryResult } from "@creditas/apollo";
import {
  getCompanyDocumentCodesInGroup,
  getCompanyDocumentCodesInGroupByGroupId
} from "../../../api/hr-portal-bff/hr-portal-bff.api";
import { envVars } from "../../../envVars";
import { useEffect, useMemo, useState } from "react";
import {
  formattedCnpjToRaw,
  rawCnpjToFormatted
} from "../../../shared/helpers/helpers";
import { useCustomProps } from "../../../contexts/CustomPropsContext";

import {
  useGetFileImportSummary,
  useGetFileResult
} from "../../../services/bff/queries";
import {
  SummaryResponse,
  Variables
} from "../../../services/bff/queries/useGetFileImportSummary";

import {
  GetFileResultResponse,
  GetFileResultVariables
} from "../../../services/bff/queries/useGetFileResult";
import { Step } from "../ImportHrEmployeeTypes";
import { useSelectGroupId } from "../../../hooks/useSelectGroupId";

interface Props {
  step: Step;
}
interface Step4ContainerHook {
  hookStateFileImportSummary: LazyQueryResult<SummaryResponse, Variables>;
  hookStateFileResult: LazyQueryResult<
    GetFileResultResponse,
    GetFileResultVariables
  >;
  getFileImportSummary: (
    options: QueryLazyOptions<Variables> | undefined
  ) => void;
  getFileResult: (
    options: QueryLazyOptions<GetFileResultVariables> | undefined
  ) => void;
  productBasePath: string;
  shouldCreateBenefitsCard: (step: Step) => boolean;
  showCompanyDocumentCodeWarning: boolean;
  companyDocumentCodeInSpreadsheet: string[];
  unregisteredCompanyDocumentCodeList: string[];
}

export const useStep4Container = ({
  step: Step
}: Props): Step4ContainerHook => {
  const { productBasePath, authHrPortalToken } = useCustomProps();
  const { isMasterUser } = useSelectGroupId();
  const [
    getFileImportSummary,
    hookStateFileImportSummary
  ] = useGetFileImportSummary();
  const [getFileResult, hookStateFileResult] = useGetFileResult();
  const [
    companyDocumentCodeInGroupList,
    setCompanyDocumentCodeInGroupList
  ] = useState<string[]>([]);
  const [
    companyDocumentCodeInSpreadsheet,
    setCompanyDocumentCodeInSpreadsheet
  ] = useState<string[]>([]);
  const [
    showCompanyDocumentCodeWarning,
    setShowCompanyDocumentCodeWarning
  ] = useState(false);
  const [
    unregisteredCompanyDocumentCodeList,
    setUnregisteredCompanyDocumentCodeList
  ] = useState<string[]>([]);

  const shouldCreateBenefitsCard = (step: Step): boolean => {
    let cardCreation = false;
    const requestCardNameColumn = step.columnMap?.REQUEST_BENEFITS_CARD;
    const indexRequestColumn = step.uploadFileAsJson!![0].findIndex(
      columnName => columnName === requestCardNameColumn
    );
    for (let i = 1; i < step.uploadFileAsJson!!.length && !cardCreation; i++) {
      if (
        step.uploadFileAsJson!![i][indexRequestColumn] &&
        step.uploadFileAsJson!![i][indexRequestColumn].toUpperCase() === "SIM"
      )
        cardCreation = true;
    }
    return cardCreation;
  };

  const getCompanyDocumentCodeListFromCommercialGroup = useMemo(async () => {
    const companyDocumentCodesInGroup = isMasterUser
      ? await getCompanyDocumentCodesInGroupByGroupId({
          url: envVars.HR_PORTAL_BFF_URL!,
          groupId: Step.companySettingsIdSelectedByMaster,
          hrPortalToken: authHrPortalToken
        })
      : await getCompanyDocumentCodesInGroup({
          url: envVars.HR_PORTAL_BFF_URL!
        });

    if (companyDocumentCodesInGroup)
      setCompanyDocumentCodeInGroupList(companyDocumentCodesInGroup);
  }, [Step.companySettingsIdSelectedByMaster, authHrPortalToken, isMasterUser]);

  const getCompanyDocumentCodeListFromSpreadsheet = useMemo(() => {
    const companyCodeIndexInSpreadsheet = Step.uploadFileAsJson?.[0].indexOf(
      Step.columnMap?.CNPJ!!
    );
    const companyCodeListFromSpreadsheet = Step.uploadFileAsJson
      ?.slice(1)
      .filter(row => row.length > 0)
      .map(
        row =>
          row[companyCodeIndexInSpreadsheet!!] &&
          row[companyCodeIndexInSpreadsheet!!].trim()
      );

    const companyCodeListWithoutDuplicates = [
      ...Array.from(new Set(companyCodeListFromSpreadsheet))
    ];
    setCompanyDocumentCodeInSpreadsheet(companyCodeListWithoutDuplicates);
  }, [Step.columnMap?.CNPJ, Step.uploadFileAsJson]);

  useEffect(() => {
    getCompanyDocumentCodeListFromCommercialGroup;
    getCompanyDocumentCodeListFromSpreadsheet;

    if (
      companyDocumentCodeInGroupList.length > 0 &&
      companyDocumentCodeInSpreadsheet.length > 0
    ) {
      const formattedCompanyDocumentCodeInGroupList = companyDocumentCodeInGroupList.map(
        item => formattedCnpjToRaw(item)
      );
      const formattedCompanyDocumentCodeInSpreadsheet = companyDocumentCodeInSpreadsheet.map(
        item => formattedCnpjToRaw(item)
      );

      formattedCompanyDocumentCodeInSpreadsheet.forEach(
        companyDocumentCodeInSpreadsheet => {
          if (
            !formattedCompanyDocumentCodeInGroupList.includes(
              companyDocumentCodeInSpreadsheet
            )
          ) {
            setShowCompanyDocumentCodeWarning(true);
            setUnregisteredCompanyDocumentCodeList(previous => [
              ...previous,
              rawCnpjToFormatted(companyDocumentCodeInSpreadsheet)
            ]);
          }
        }
      );
    }
  }, [
    companyDocumentCodeInSpreadsheet,
    Step.columnMap?.CNPJ,
    companyDocumentCodeInGroupList,
    Step.uploadFileAsJson,
    getCompanyDocumentCodeListFromSpreadsheet,
    getCompanyDocumentCodeListFromCommercialGroup,
    isMasterUser
  ]);

  return {
    hookStateFileImportSummary,
    hookStateFileResult,
    getFileImportSummary,
    getFileResult,
    productBasePath,
    shouldCreateBenefitsCard,
    companyDocumentCodeInSpreadsheet,
    showCompanyDocumentCodeWarning,
    unregisteredCompanyDocumentCodeList
  };
};
