import { gql, useQuery } from "@creditas/apollo";

export const GET_IMPORT_COLUMNS_MAP = gql`
  query {
    employeeImportGetColumnsMap {
      columnsMap
    }
  }
`;

export interface Response {
  employeeImportGetColumnsMap: {
    columnsMap: object;
  };
}

export const useGetColumnsMap = () =>
  useQuery<Response>(GET_IMPORT_COLUMNS_MAP);
