import React from "react";
import { styled, utilities } from "@creditas/stylitas";
import { Content } from "@creditas/icons";

const RoundedPlusIcon = styled(Content.Plus)`
  background-color: ${({ theme }) =>
    utilities.palette.color(theme, "primary03")};
  border-radius: 50%;
`;

export default RoundedPlusIcon;
