import { Product } from "./product";

export const getImportTypeByProduct = productType => {
  switch (productType) {
    case Product.PAYROLL:
      return "EMPLOYEE";
    case Product.PAYROLL_AND_BENEFITS:
      return "HR_EMPLOYEE";
    case Product.BENEFITS:
      return "BENEFITS_EMPLOYEE";
    default:
      return "";
  }
};
