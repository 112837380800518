import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { InputSelect } from "@creditas/form-elements";
import { Typography } from "@creditas/typography";
import { Button } from "@creditas/button";
import { Container } from "@creditas/layout";

import Flex from "../../components/Styled/Flex";
import FlexItem from "../../components/Styled/FlexItem";
import { IndexColumnsMap } from "../../pages/ImportHrEmployee/step2/Step2Types";
import { SystemValue } from "../../components/MatchColumn/interfaces";
import { StyledPrimaryButton } from "../../shared/styles/primary-button.styles";

type MapObject<V = any> = { [key: string]: V };

function stringListToObjectWithoutValues(
  arrayOfStrings: string[]
): MapObject<null> {
  if (!arrayOfStrings) {
    return {};
  }
  return arrayOfStrings.reduce((accumulator, item: string) => {
    return { ...accumulator, [item]: null };
  }, {});
}

function isObjectEmpty(values: any) {
  return Object.keys(values).length === 0;
}

const valueComponent = (title, values, options, onChange, mapName, t) => {
  if (isObjectEmpty(values)) {
    return null;
  }

  return (
    <>
      <br />
      <div>
        <Typography>{title}</Typography>
        <Flex>
          {Object.keys(values).map(key => (
            <FlexItem basis="25%" key={key}>
              <Typography variant="form" style={{ marginBottom: 8 }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("hr.matchValues.subtitle")}
                </span>{" "}
                {key}
              </Typography>
              <InputSelect
                data-testid={key}
                label="Valor no sistema"
                size="comfortable"
                options={options}
                onChange={onChange(key, mapName)}
              />
            </FlexItem>
          ))}
        </Flex>
      </div>
    </>
  );
};

interface Props {
  onBackButton?: () => void;
  uploadSystemValues: SystemValue[];
  uploadFileAsJson: string[][];
  indexColumnsMap: IndexColumnsMap;
  systemValuesCellMappingNeeded: string[];
  loading: boolean;
  submitValuesMapping: (valuesMap: any) => void;
}

const MatchValues = ({
  onBackButton,
  uploadSystemValues,
  uploadFileAsJson,
  indexColumnsMap,
  systemValuesCellMappingNeeded,
  loading = false,
  submitValuesMapping
}: Props) => {
  const { t } = useTranslation();

  const [mappedValues, setMappedValues] = useState<
    MapObject<MapObject<null | string>>
  >(() => {
    let baseValues = {};
    systemValuesCellMappingNeeded.forEach(value => {
      let cellValuesMap;
      uploadSystemValues.forEach(systemValue => {
        if (systemValue.key == value) {
          cellValuesMap = systemValue.valuesMap;
        }
      });

      const differentValuesInFile = uploadFileAsJson.reduce(
        (differentValuesInFile, nextValue, index) => {
          const valueIndex = indexColumnsMap[value];
          return index === 0 ||
            nextValue[valueIndex] === undefined ||
            differentValuesInFile.includes(nextValue[valueIndex])
            ? differentValuesInFile
            : [...differentValuesInFile, nextValue[valueIndex]];
        },
        []
      );

      const differentValuesObject = stringListToObjectWithoutValues(
        differentValuesInFile
      );
      baseValues = {
        ...baseValues,
        [cellValuesMap.name]: differentValuesObject
      };
    });
    return baseValues;
  });

  const setTypeForKey = (key, mapName) => event => {
    setMappedValues({
      ...mappedValues,
      [mapName]: {
        ...mappedValues[mapName],
        [key]: event.target.value == "" ? null : event.target.value
      }
    });
  };

  const hasAnyValueNotMapped = () => {
    return Object.values(mappedValues).some(systemValue => {
      return Object.values(systemValue).some(
        differentValue => differentValue === null
      );
    });
  };

  const submit = () => {
    if (loading) {
      return;
    }
    submitValuesMapping(mappedValues);
  };

  const selectValueMapLabel = systemValueName => {
    switch (systemValueName) {
      case "statusMap":
        return t("hr.matchValues.textStatus");
      case "employeeTypeMap":
        return t("hr.matchValues.textTypeEmployee");
      default:
        return t("hr.matchValues.textDefault");
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" data-testid="map-value-title">
        {t("hr.matchValues.title")}
      </Typography>
      {systemValuesCellMappingNeeded.map(value => {
        let cellValuesMap;
        uploadSystemValues.forEach(systemValue => {
          if (systemValue.key == value) {
            cellValuesMap = systemValue.valuesMap;
          }
        });

        return valueComponent(
          selectValueMapLabel(cellValuesMap.name),
          mappedValues[cellValuesMap.name],
          cellValuesMap.values,
          setTypeForKey,
          cellValuesMap.name,
          t
        );
      })}
      <div style={{ marginTop: 20, marginBottom: 80, textAlign: "right" }}>
        {onBackButton && (
          <Button
            variant="tertiary"
            style={{ color: "#1F2D27" }}
            onClick={onBackButton}
          >
            {t("hr.matchValues.backButton")}
          </Button>
        )}
        <StyledPrimaryButton
          onClick={submit}
          isLoading={loading}
          data-testid="submit"
          disabled={hasAnyValueNotMapped()}
        >
          {t("hr.matchValues.sendButton")}
        </StyledPrimaryButton>
      </div>
    </Container>
  );
};

export default MatchValues;
