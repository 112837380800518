/* eslint-disable max-lines-per-function */
import { useTranslation } from "react-i18next";
import React, { useState, useCallback } from "react";

import { Button } from "@creditas/button";
import { Container, Grid } from "@creditas/layout";
import { Typography } from "@creditas/typography";
import { InputSelect } from "@creditas/form-elements";
import { Table, TableBody, TableRow, TableCell } from "@creditas/table";

import { EmployeeImportColumnMatch } from "../../services/bff/mutations";
import { MapColumnProps, RequiredSystemValue, SystemValue } from "./interfaces";
import { rootGrid } from "./MatchColumn.styles";
import { StyledPrimaryButton } from "../../shared/styles/primary-button.styles";

const filterRequiredSystemValues = (systemValues: SystemValue[]) =>
  systemValues.reduce((accumulator: RequiredSystemValue[], systemValue) => {
    return systemValue.required
      ? [...accumulator, { key: systemValue.key, label: systemValue.label }]
      : accumulator;
  }, []);

const lineWithoutEmpty = (line: string[]) => {
  const arrayWithouEmptyIndex: string[] = [];
  for (const item of line) {
    arrayWithouEmptyIndex.push(item || "");
  }
  return arrayWithouEmptyIndex;
};

const isTextSimilar = (text: string, otherText: string) => {
  return (
    text
      ?.toLowerCase()
      .normalize("NFD")
      .trim() ==
    otherText
      ?.toLowerCase()
      .normalize("NFD")
      .trim()
  );
};

const MatchColumn = ({
  onBackButton,
  preview,
  loading,
  systemValues,
  submitColumnMapping,
  lastColumnMap
}: MapColumnProps) => {
  const { t } = useTranslation();

  const defaultInputValues = () => {
    const defaultInputs = Array(preview[0].length).fill("");
    for (let i = 0; i < preview[0].length; i++) {
      const index = systemValues.findIndex(systemValue =>
        isTextSimilar(systemValue.label, preview[0][i])
      );
      if (index > -1) {
        defaultInputs[i] = systemValues[index].key;
      }
    }
    return defaultInputs;
  };

  const inputsInitialValue = () => {
    const columnsMap = lastColumnMap || {};
    const newInputs = defaultInputValues();
    for (const key of Object.keys(columnsMap)) {
      const index = preview[0].findIndex(previewHeader => {
        return previewHeader?.trim() === columnsMap[key];
      });
      if (index === -1) continue;
      newInputs[index] = key;
    }
    return newInputs;
  };

  const [inputs, setInputs] = useState(inputsInitialValue());
  const [requiredSystemValues] = useState(
    filterRequiredSystemValues(systemValues)
  );

  const setColumn = columnIndex => event => {
    const newInputs = [...inputs];
    const value = event.target.value;
    const hasValue = newInputs.indexOf(value);
    if (hasValue >= 0) newInputs[hasValue] = "";
    newInputs[columnIndex] = value;
    setInputs(newInputs);
  };

  const inputLines = () => (
    <TableRow>
      {inputs.map((value, columnIndex) => (
        <TableCell key={columnIndex}>
          <InputSelect
            onChange={setColumn(columnIndex)}
            options={systemValuesAsOptions()}
            label="Selecione"
            value={value}
            size="comfortable"
            width="200px"
            data-testid={`input${columnIndex}`}
          />
        </TableCell>
      ))}
    </TableRow>
  );

  const wrapLinesFromPreview = useCallback(() => {
    return preview.map((line, rowIndex) => (
      <TableRow key={`row${rowIndex}`}>
        {lineWithoutEmpty(line).map((cell, cellIndex) => (
          <TableCell key={`row${rowIndex}cell${cellIndex}`}>{cell}</TableCell>
        ))}
      </TableRow>
    ));
  }, [inputs]);

  const systemValuesAsOptions = () =>
    systemValues.map(({ key, label }) => ({
      value: key,
      text: label
    }));

  const hasAllRequiredSystemValues = () =>
    !requiredSystemValues
      .map(systemValue => systemValue.key)
      .every((key: string) => inputs.includes(key));

  const submit = () => {
    if (loading) {
      return;
    }
    const payload: EmployeeImportColumnMatch = inputs.reduce(
      (accumulator, value, index) => ({
        ...accumulator,
        ...(value ? { [value]: index } : {})
      }),
      {}
    );
    submitColumnMapping(payload);
  };

  return (
    <Container maxWidth="lg">
      <Grid options={rootGrid}>
        <Typography
          variant="h4"
          data-testid="map-column-title"
          style={{ marginBottom: 16 }}
        >
          {t("label.matchColumns.title")}
        </Typography>
        <div style={{ marginBottom: 16 }}>
          <Typography style={{ marginBottom: 8 }}>
            {t("label.matchColumns.neededFieldsText")}
          </Typography>
          <Typography style={{ marginBottom: 8 }}>
            {t("label.matchColumns.fieldListHelp")}
          </Typography>
          <Typography style={{ fontWeight: 800 }}>
            {t("label.matchColumns.unmatchedFieldsWarning")}
          </Typography>
        </div>
        <div style={{ overflowX: "scroll" }}>
          <Table fullWidth>
            <TableBody variant="striped">
              {inputLines()}
              {wrapLinesFromPreview()}
            </TableBody>
          </Table>
        </div>
        <div style={{ marginTop: 20, marginBottom: 80, textAlign: "right" }}>
          {onBackButton && (
            <Button
              variant="tertiary"
              style={{ color: "#1F2D27" }}
              onClick={onBackButton}
            >
              {t("label.matchColumns.backButton")}
            </Button>
          )}
          <StyledPrimaryButton
            onClick={submit}
            isLoading={loading}
            data-testid="submit"
            disabled={hasAllRequiredSystemValues()}
          >
            {t("label.matchColumns.sendButton")}
          </StyledPrimaryButton>
        </div>
      </Grid>
    </Container>
  );
};

export default MatchColumn;
