import { styled, css } from "@creditas-ui/react";
import { CreditasUITheme } from "../../../../../@types/global";

const progressBarContainer = (theme: CreditasUITheme) => css`
  height: 4px;
  width: 100%;
  background-color: ${theme.colors.neutral[15]};
  border-radius: 10px;
`;

export const HrProgressBarContainer = styled.div`
  ${({ theme }) => progressBarContainer(theme)}
`;

export const HrProgressBarFiller = styled.div<{ percentage: number }>`
  height: 4px;
  width: ${({ percentage }) => percentage}%;
  background-color: #49e295;
  border-radius: inherit;
  transition: "width 1s ease-in-out";
`;
