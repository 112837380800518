import { useToaster } from "@creditas/toaster";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useCustomProps } from "../../../contexts/CustomPropsContext";
import { emitEvent, Sentry } from "../../../helpers";
import {
  ImportStatus,
  useGetFileImportStatus
} from "../../../services/bff/queries/useGetFileImportStatus";
import { Step } from "../ImportHrEmployeeTypes";
import UploadSpreadsheetComponent from "./components/UploadSpreadSheet/UploadSpreadsheetComponent";
import { useStep1Container } from "./Step1ContainerHook";
interface Props {
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
}

export const Step1Container: React.FC<Props> = ({ step, setStep }: Props) => {
  const {
    handleSend,
    handleDownload,
    setFile,
    setCompanySettingsIdSelectedByMaster,
    companySettingsIdSelectedByMaster,
    companySettingsIdOfImportation
  } = useStep1Container(setStep);
  const { productBasePath, productType } = useCustomProps();
  const [getFileImportStatus, hookState] = useGetFileImportStatus();
  const { push } = useToaster();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    emitEvent({
      event: "gaCreditasEvent",
      category: "EmployeeImport",
      action: "LoadEmployeeImportStep1"
    });
  }, []);

  useEffect(() => {
    step.uploadKey &&
      getFileImportStatus({
        variables: {
          id: step.uploadKey
        }
      });
  }, [step.uploadKey, getFileImportStatus]);

  useEffect(() => {
    if (hookState.error?.message) {
      const exception = new Error(hookState.error?.message);
      const extra = {
        flow: "benefits",
        request: "getFileImportStatus",
        correlationId: step.uploadKey
      };
      Sentry.captureExceptionHint(exception, extra);
    }

    if (hookState.data?.getFileImportStatus !== undefined) {
      if (
        ((hookState.data?.getFileImportStatus
          .status as unknown) as ImportStatus) === "ERROR" &&
        step.current === 1
      ) {
        const exception = new Error(t("hr.globalErrorMessage.readingFile"));
        const extra = {
          flow: "benefits",
          request: "getFileImportStatus",
          correlationId: step.uploadKey
        };
        Sentry.captureExceptionHint(exception, extra);
        push(t("hr.globalErrorMessage.readingFile"), {
          level: "danger",
          delay: -1
        });
        history.push(productBasePath);
      }

      if (
        ((hookState.data?.getFileImportStatus
          .status as unknown) as ImportStatus) === "WAITING"
      ) {
        setStep(previousState => ({
          ...previousState,
          current: step.current + 1
        }));
      }
    }
  }, [history, hookState, productBasePath, push, setStep, step, t]);

  return (
    <UploadSpreadsheetComponent
      onSend={handleSend}
      onDownload={handleDownload}
      setFile={setFile}
      setCompanySettingsIdSelectedByMaster={
        setCompanySettingsIdSelectedByMaster
      }
      companySettingsIdOfImportation={companySettingsIdOfImportation}
      companySettingsIdSelectedByMaster={companySettingsIdSelectedByMaster}
      productType={productType}
      salaryType={step.salaryType}
    />
  );
};
