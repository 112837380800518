export const rootGrid = {
  gridColumns: {
    xs: "",
    md: "1fr",
    lg: "1fr"
  },
  gridColumnGap: {
    xs: "0px",
    md: "0px",
    lg: "0px"
  }
};
