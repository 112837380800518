import { UserType } from "./interfaces/userType";
import { EntryFlow } from "./helpers/entryFlow";
import { Product } from "./helpers/product";

export * from "./index.spa";

export type AppCustomProps = {
  api: string;
  language: string;
  productBasePath: string;
  authToken: string;
  authHrPortalToken?: string;
  userType: UserType;
  productType: Product;
  entryFlow: EntryFlow;
  companySettingsId?: number;
  onExit?: Function;
};

export const defaultCustomProps: AppCustomProps = {
  api: "DEXTER",
  language: "pt-BR",
  productBasePath: "",
  authToken: "",
  authHrPortalToken: undefined,
  userType: UserType.MANAGER,
  productType: Product.PAYROLL,
  entryFlow: EntryFlow.DEFAULT,
  onExit: undefined
};
