import { styled } from "@creditas/stylitas";

interface Props {
  readonly basis: string;
}

export default styled.div<Props>`
  padding: 0 8px;
  flex-basis: ${props => props.basis};
`;
