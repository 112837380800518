import { css } from "@creditas-ui/react";
import { CreditasUITheme } from "../../../../../@types/global";

const scrollBar = (theme: CreditasUITheme) => css`
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: absolute;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${theme.colors.neutral[20]};
  }
  &::-webkit-scrollbar-track {
    margin: 10px 0;
  }
`;

export { scrollBar };
